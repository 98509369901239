import { Pipe, PipeTransform } from '@angular/core';
import { IAccountData } from 'src/app/model/interfaces/account-admin.interface';

/**
 * Create short name by the combination of firstname and lastname
 * @param accountData - The account data
 * @returns The shor name
 */
@Pipe({
  name: 'shortName'
})
export class ShortNamePipe implements PipeTransform {
  transform(accountData: IAccountData): string {
    if (!accountData.firstName || !accountData.lastName) {
      return accountData.email.substring(0, 2).toUpperCase();
    }
    return accountData.firstName.charAt(0).concat(accountData.lastName.charAt(0)).toUpperCase();
  }
}
