import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mt-cogsig-top-info-banner',
  templateUrl: './cogsig-top-info-banner.component.html',
  styleUrls: ['./cogsig-top-info-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CogsigTopInfoBannerComponent {
  /**
   * Hold to know first user login guide is completed
   * @defaultValue false
   */
  @Input() isGuideExist = false;

  /**
   * Current page is guide page
   * @defaultValue false
   */
  @Input() isGuidePage = false;
}
