<!-- Submenu section -->
<ul class="mt-submenu-sidebar-link-wrapper mt-white sidebar-submenu">
  <ng-container *ngFor="let menu of menus">
    <ng-container [ngSwitch]="menu.name">
      <div
        *ngSwitchCase="'SIGNATURES'"
        mt-claim-authorization
        [requiredClaim]="'Signature:View'"
      >
        <li>
          <a [routerLink]="menu.url" routerLinkActive="active" class="show">
            <ng-container
              [ngTemplateOutlet]="renderSideMenuData"
              [ngTemplateOutletContext]="{ menu }"
            ></ng-container>
          </a>
        </li>
      </div>

      <div
        *ngSwitchCase="'Default_Signatures'"
        mt-claim-authorization
        [requiredClaim]="'DefaultSignatures:View'"
      >
        <li>
          <a [routerLink]="menu.url" routerLinkActive="active" class="show">
            <ng-container
              [ngTemplateOutlet]="renderSideMenuData"
              [ngTemplateOutletContext]="{ menu }"
            ></ng-container>
          </a>
        </li>
      </div>
    </ng-container>
  </ng-container>

  <!-- Displays sidemenu data -->
  <ng-template let-menu="menu" #renderSideMenuData>
    <div class="mt-submenu-icon">
      <mt-svg class="mt-white" src="{{ menu.image }}"> </mt-svg>
    </div>
    <span class="mt-submenu-title inline-block" [transloco]="menu.name"></span>
    <span *ngIf="menu.hasCounter" class="mt-submenu-counter">
      {{ signatureCount }}
    </span>
  </ng-template>
</ul>
