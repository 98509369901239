import { Directive, ElementRef, Output, EventEmitter, HostListener, OnInit } from '@angular/core';
import { fromEvent } from 'rxjs';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsideDirective implements OnInit {
  /**
   * Determines the outside click event of sidebar menu
   */
  @Output() clickOutside = new EventEmitter<void>();

  captured = false;

  constructor(private elRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])

  /**
   * Triggers the click event of the outer section of sidebar menu
   * @param target - Target element of clicked event
   * @returns void
   */
  onClick(target: unknown): void {
    if (!this.captured) {
      return;
    }
    if (
      !this.elRef.nativeElement.contains(target) &&
      !(target as Element).className?.includes?.('toggle-submenu-locked')
    ) {
      this.clickOutside.emit();
    }
  }

  ngOnInit() {
    fromEvent(document, 'click', { capture: true })
      .pipe(take(1))
      .subscribe(() => (this.captured = true));
  }
}
