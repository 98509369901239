import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map, switchMap } from 'rxjs/operators';
import {
  INavigationMenu,
  INavigationSidebarSubmenuCount
} from 'src/app/modules/root/model/navigation-sidebar-menu.interface';
import { EmployeeService } from 'src/app/services/employee/employee.service';
import { BehaviorSubject, combineLatest, forkJoin, iif, of, tap } from 'rxjs';
import { GroupService } from 'src/app/services/group/group.service';
import { GetStartedGuideService } from 'src/app/services/get-started-guide/get-started-guide.service';
import { SignatureService } from 'src/app/services/signature/signature.service';
import { EventService } from 'src/app/services/event/event.service';
import { TargetGroupService } from 'src/app/services/target-group/target-group.service';
import { CampaignService } from 'src/app/services/campaign/campaign.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { SIDEBAR_MENU } from '../../../model/navigation-sidebar.const';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/services/util/util.service';
import { NavigationSidebarService } from 'src/app/services/navigation-sidebar/navigation-sidebar.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TopInfoBannerService } from 'src/app/services/top-info-banner/top-info-banner.service';
import { IntercomService } from '@services/intercom/intercom.service';
import { AdblockService } from '@services/adblock/adblock.service';
import { IntegrationsService } from '@modules/integrations/services/integrations.service';
import { INTEGRATIONS } from '@modules/integrations/model/integration.const';
import { cloneDeep } from 'lodash-es';
import { AccountService, SUBSCRIPTION_DATA_CACHE } from '@services/account/account.service';
import { SuperAdminAccountData } from '@model/interfaces/account-admin.interface';
import { CustomerEmails } from '@services/user/user-service.interface';

@Component({
  selector: 'mt-navigation-sidebar',
  templateUrl: './navigation-sidebar.component.html',
  styleUrls: ['./navigation-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationSidebarComponent implements OnInit {
  isCognismSignatures = environment.isCogSig;
  activeRoute = this.router.url;
  isSettingsTooltipHovered = false;
  sidebarMenus = SIDEBAR_MENU;
  isAdvanceSettingsActive = false;
  submenuDropdownOpened = false;
  submenuSection = '';

  /**
   * Determines integrations const values
   */
  integrations = INTEGRATIONS;

  /**
   * Determines the counter of submenu
   * @defaultValue {
   * campaigns: 0,
   * departments: 0,
   * employees: 0,
   * employeesOutdated: 0,
   * events: 0,
   * notifications: 0,
   * signatures: 0,
   * targetGroups: 0
   *}
   */
  submenuCounters: INavigationSidebarSubmenuCount = {
    campaigns: 0,
    departments: 0,
    employees: 0,
    employeesOutdated: 0,
    events: 0,
    notifications: 0,
    signatures: 0,
    targetGroups: 0
  };
  /**
   * For plan child menu
   */
  isPlanAndBillingDropdown = true;

  tooltips = {
    languageTooltipOpened: false,
    message: '',
    opened: {} as INavigationMenu
  };

  /**
   * Show right corner icon when intercom prevented by adblock extension
   */
  private _isIntercomExist$ = new BehaviorSubject<boolean>(true);
  isIntercomExist$ = this._isIntercomExist$.asObservable();

  /**
   * Observer used when loading campaigns count
   */
  private _loadCampaignsCount$ = new BehaviorSubject(0);
  getCampaignsCount$ = this._loadCampaignsCount$.asObservable().pipe(
    switchMap(() => this.authService.authorizationScope$),
    switchMap(authorizationScope =>
      combineLatest([
        iif(() => authorizationScope.isUserOnBasicPlan, of(0), this.campaignService.count()),
        iif(() => authorizationScope.isUserOnBasicPlan, of(0), this.eventService.count()),
        iif(() => !authorizationScope.hasTargetLists, of(0), this.targetGroupService.countTargetGroups()),
        iif(() => !authorizationScope.hasNotifs, of(0), this.notificationService.count())
      ])
    ),
    map(([campaignCount, eventCount, targetGroupCount, notificationCount]) => ({
      campaignCount,
      eventCount,
      notificationCount,
      targetGroupCount
    })),
    tap(data => {
      this.submenuCounters.campaigns = data.campaignCount;
      this.submenuCounters.events = data.eventCount;
      this.submenuCounters.targetGroups = data.targetGroupCount;
      this.submenuCounters.notifications = data.notificationCount;
    })
  );

  /**
   * Observable is used to get the count
   */
  counter$ = this.navigationSidebarService.getSidebarSubmenuCount().pipe(
    tap(data => {
      switch (data.menu) {
        case 'EMPLOYEE':
        case 'employees': {
          this.submenuCounters.employees += data.amount;
          break;
        }
        case 'employeesOutdated': {
          if (data.amount > 1) {
            this.submenuCounters.employeesOutdated = data.amount;
          } else {
            this.submenuCounters.employeesOutdated += data.amount;
          }
          break;
        }
        case 'groups': {
          this.submenuCounters.departments += data.amount;
          break;
        }
        case 'signatures': {
          this.submenuCounters.signatures += data.amount;
          break;
        }
        case 'campaigns': {
          this.submenuCounters.campaigns += data.amount;
          break;
        }
        case 'events': {
          this.submenuCounters.events += data.amount;
          break;
        }
        case 'notification': {
          this.submenuCounters.notifications += data.amount;
          break;
        }
        case 'targetGroups': {
          this.submenuCounters.targetGroups += data.amount;
          break;
        }
      }
      this.submenuCounters = {
        ...this.submenuCounters
      };
    })
  );

  /**
   * Observer used when loading employees count
   */
  private _loadEmployeesCount$ = new BehaviorSubject(0);
  getEmployeesCount$ = this._loadEmployeesCount$.asObservable().pipe(
    switchMap(() =>
      combineLatest([
        this.employeeService.count(),
        iif(() => !this.isCognismSignatures, this.employeeService.countOutdatedEmployees(), of(0)),
        this.groupsService.count()
      ])
    ),
    map(([employeesCount, employeesOutdatedCount, targetGroupsCount]) => ({
      employeesCount,
      employeesOutdatedCount,
      targetGroupsCount
    })),
    tap(data => {
      this.submenuCounters.employees = data.employeesCount;
      this.submenuCounters.employeesOutdated = data.employeesOutdatedCount;
      this.submenuCounters.departments = data.targetGroupsCount;
    })
  );

  /**
   * Observer used when loading signature count
   */
  private _loadSignatureCount$ = new BehaviorSubject(0);
  getSignatureCount$ = this._loadSignatureCount$.asObservable().pipe(
    switchMap(() => this.signatureService.count()),
    tap(data => (this.submenuCounters.signatures = data))
  );

  /**
   * Loads the count data for submenus
   */
  loadCount$ = this.navigationSidebarService.isSubMenuOpened$.pipe(
    filter(isSubMenuOpened => isSubMenuOpened),
    switchMap(() =>
      combineLatest([this.getEmployeesCount$, this.getSignatureCount$, this.getCampaignsCount$, this.counter$])
    )
  );

  /**
   * Loads the super admin account data
   */
  loadSuperAdminData$ = of(this.accountService.getSuperAdminAccountData()).pipe(
    switchMap(superAdminAccountData => {
      return iif(
        () => superAdminAccountData.isSuperAdmin && !this.isCognismSignatures,
        combineLatest([
          of(superAdminAccountData),
          this.topInfoBannerService.loadAllCustomers$,
          this.authService.recentCustomersEmail$
        ]),
        forkJoin([of({} as SuperAdminAccountData), of([] as CustomerEmails[]), of([])])
      );
    }),
    map(([superAdminAccountData, allCustomers, recentCustomersEmail]) => ({
      superAdminAccountData,
      allCustomers,
      recentCustomersEmail
    }))
  );

  /**
   * Variable used to show or hide the get started button
   */
  loadInfo$ = combineLatest([
    this.topInfoBannerService.loadExteralAdminAccounts$,
    this.authService.authorizationScope$,
    this.navigationSidebarService.isHiddenGuideMenu$,
    this.guideService.getGuideDetails$,
    this.guideService.isGuidePage$,
    this.navigationSidebarService.isMenuLocked$,
    this.navigationSidebarService.isSubMenuOpened$,
    this.navigationSidebarService.isRecipientsLeads$,
    this.navigationSidebarService.hasSubscription$,
    this.loadSuperAdminData$,
    this.intercomService.hideShowDefaultLuncher$
  ]).pipe(
    map(
      ([
        accountData,
        authorizationScope,
        isGuideMenuHidden,
        guideData,
        isGuidePage,
        submenuLocked,
        submenuOpened,
        recipientsLeads,
        hasSubscription,
        superAdminData
      ]) => ({
        accountData,
        allCustomers: superAdminData.allCustomers || [],
        authorizationScope,
        isGuideMenuHidden,
        forceAllow: hasSubscription.forceAllow,
        hasChargifySubscription: !!localStorage.getItem(SUBSCRIPTION_DATA_CACHE),
        hasSubscription: hasSubscription.hasSubscription,
        hasTestTime: hasSubscription.hasTestTime,
        isGuideExist: guideData.isGuideExist,
        isGuidePage,
        isUserFromAngularTs: hasSubscription.isUserFromAngularTs,
        recentCustomersEmail: superAdminData.recentCustomersEmail || [],
        recipientsLeads,
        submenuLocked,
        submenuOpened,
        superAdminAccountData: superAdminData.superAdminAccountData,
        trialDaysLeft: hasSubscription.trialDaysLeft
      })
    )
  );

  /**
   * Variable used to show or hide the My Integrations submenu
   */
  getMyIntegrations$ = this.navigationSidebarService.loadMyIntegrations$.pipe(
    switchMap(() =>
      iif(
        () => !this.isCognismSignatures,
        this.integrationsService.loadIntegrationData$,
        this.integrationsService.loadCogsigIntegrationData$
      )
    ),
    map(integrationDetails =>
      this.integrationsService.getActiveIntegrations(cloneDeep(this.integrations.list), integrationDetails)
    )
  );

  /**
   * Used the sync problem list count by filter data
   */
  getOutDatedCountByFilterData$ = this.navigationSidebarService.syncProblemListCount$.pipe(
    tap(data => (this.submenuCounters.employeesOutdated = data))
  );

  constructor(
    private accountService: AccountService,
    private authService: AuthService,
    private campaignService: CampaignService,
    private employeeService: EmployeeService,
    private eventService: EventService,
    private groupsService: GroupService,
    private integrationsService: IntegrationsService,
    private intercomService: IntercomService,
    private navigationSidebarService: NavigationSidebarService,
    private notificationService: NotificationService,
    private signatureService: SignatureService,
    private targetGroupService: TargetGroupService,
    public adblockService: AdblockService,
    public guideService: GetStartedGuideService,
    public route: ActivatedRoute,
    public router: Router,
    public topInfoBannerService: TopInfoBannerService,
    public utilsService: UtilService
  ) {
    // Enable intercom luncher
    this.intercomService.isHideIntercomLuncher(false);
  }

  ngOnInit() {
    const isLock = localStorage.getItem('submenuLocked') === 'true';
    if (isLock) {
      this.navigationSidebarService.isMenuLocked = isLock;
      this.navigationSidebarService.setSubMenuIsOpened(isLock);
    }
    this.getActiveMenu();
    setTimeout(() => {
      this._isIntercomExist$.next(this.intercomService.isIntercomIconAvailable());
    }, 1000);
  }

  /**
   * Show or hide the submenu section by click over the menu section
   * @remarks
   * The menu here are like `campaigns`, `employees`, `marketings`..
   * @param menu - The submenu will be open or hide as per selected menu
   * @param submenuLocked - Submenu item is locked
   * @param submenuOpened - Submenu item is opened
   */
  toggleSubmenuSidebar(menu: string, submenuLocked: boolean, submenuOpened: boolean): void {
    if (submenuOpened) {
      if (this.submenuSection === menu && (menu !== 'settings' || !submenuLocked)) {
        this.updateSubmenuStatus(false, '');
      } else {
        this.countAsync(menu);
        this.submenuSection = menu;
      }
    } else {
      this.countAsync(menu);
      this.updateSubmenuStatus(true, menu);
      this.tooltips.opened['settings'] = false;
      this.tooltips.languageTooltipOpened = false;
    }
  }

  /**
   * Updates the submenu status like opened or closed and store it in a localstorage
   * @param status - The submenu status like opened or closed as a `true` or `false`
   * @param menu - The menu name if submenu status is `true` else it will be empty string
   */
  updateSubmenuStatus(status: boolean, menu: string) {
    this.navigationSidebarService.setSubMenuIsOpened(status);
    this.submenuSection = menu;
  }

  /**
   * Closes the submenu section by outside click of the sidebar only if submenu is unlocked
   * @param submenuLocked - Submenu item is locked
   * @param event - Object of `MouseEvent`
   */
  clickOutside(submenuLocked: boolean, event?: MouseEvent): void {
    const relatedTarget = event?.relatedTarget as EventTarget;
    const relatedElementClass = (relatedTarget as HTMLElement)?.classList.value;

    // Stop sidebar menu closing when mouse move on menu tooltip
    if (relatedTarget && !/tooltip-arrow|tooltip-inner|side-bar-tooltip/.test(relatedElementClass)) {
      this.activeRoute = this.router.url;
      this.closeSubmenuSidebar(submenuLocked, true);
    }
  }

  /**
   * Get active menu on page load
   * @remarks
   * This method will returns the menu name as a string
   * @returns The active menu name like 'employees'
   */
  getActiveMenu(): void {
    let menu = '';
    const routeString = this.route.snapshot.firstChild?.routeConfig?.path;
    if (routeString) {
      if (['employees', 'groups', 'dataprofiles'].includes(routeString)) {
        menu = 'employees';
      } else if (['signatures'].includes(routeString)) {
        menu = 'signatures';
      } else if (['campaigns', 'events', 'overview', 'recipients'].includes(routeString)) {
        menu = 'campaigns';
      } else if (['getstartedguide'].includes(routeString)) {
        this.submenuSection = '';
        this.navigationSidebarService.setSubMenuIsOpened(false);
      } else if (['integrations'].includes(routeString)) {
        menu = 'integrations';
      } else if (['booking'].includes(routeString)) {
        menu = 'settings';
      } else if (['settings'].includes(routeString)) {
        menu = 'settings';
        if (this.router.url !== '/settings/administration') {
          this.isAdvanceSettingsActive = true;
          this.submenuDropdownOpened = true;
        }
      } else {
        menu = 'dashboard';
        this.submenuSection = '';
        this.navigationSidebarService.setSubMenuIsOpened(false);
      }
    } else {
      menu = 'dashboard';
      this.submenuSection = '';
      this.navigationSidebarService.setSubMenuIsOpened(false);
    }

    this.submenuSection = menu;
  }

  /**
   * Checks if respective submenu of selected menu is opened
   * @param menu - The selected menu like `employees`, `campaigns`, `signatures`..
   * @param submenuOpened - Submenu item is opened
   * @returns The status of submenu section like it is opened or closed of selected menu as a boolean value
   */
  isSubmenuSectionActive(menu: string, submenuOpened: boolean): boolean {
    const routeString = this.route.snapshot.firstChild?.routeConfig?.path;
    if (routeString) {
      if (['employees', 'groups', 'dataprofiles'].includes(routeString)) {
        this.activeRoute = '/employees/list';
      } else if (['signatures'].includes(routeString)) {
        this.activeRoute = '/signatures/list';
      } else if (['campaigns', 'events', 'overview'].includes(routeString)) {
        this.activeRoute = '/campaigns/list';
      } else if (['integrations'].includes(routeString)) {
        this.activeRoute = '/integrations/filter';
      } else if (['getstartedguide'].includes(routeString)) {
        this.activeRoute = '';
        if (menu === 'guide') {
          return true;
        }
      } else if (['settings'].includes(routeString)) {
        this.activeRoute = '';
        if (menu === 'settings' && this.submenuSection === 'settings') {
          return true;
        }
      }
    }
    return submenuOpened && this.submenuSection === menu;
  }

  /**
   * Hides the respective menu's tooltip
   * @remarks
   * If the menu is `settings` then the method will close the language tooltip also
   * @param menu - Selected menu like `employees`, `signatures`, `campaigns`..
   */
  hideTooltip(menu: string): void {
    if (menu === 'settings') {
      this.isSettingsTooltipHovered = false;
      setTimeout(() => {
        this.isSettingsTooltipHovered = false;
        this.tooltips.opened[menu] = false;
        this.tooltips.languageTooltipOpened = false;
      }, 300);
    } else {
      this.tooltips.opened[menu] = false;
    }
  }

  /**
   * Loads the count of respective submenu
   * @param menu - Selected menu like `employees`, `signatures`, `campaigns`..
   */
  countAsync(menu: string): void {
    switch (menu) {
      // marketings
      case 'campaigns': {
        this._loadCampaignsCount$.next(1);
        break;
      }
      // company
      case 'employees': {
        this._loadEmployeesCount$.next(1);
        break;
      }
      case 'signatures': {
        this._loadSignatureCount$.next(1);
        break;
      }
    }
  }

  /**
   * Shows settings tooltip with container and manual triggers
   * @param tooltip - Settings tooltip reference id
   * @param submenuLocked - Submenu item is locked
   * @param submenuOpened - Submenu item is opened
   */
  showSettingsTooltip(tooltip: { open: () => void }, submenuLocked: boolean, submenuOpened: boolean) {
    if (!(submenuOpened && this.submenuSection == 'settings' && !submenuLocked)) {
      this.tooltips.opened.settings = true;
      this.isSettingsTooltipHovered = false;
      this.tooltips.languageTooltipOpened = false;
      tooltip.open();
    }
  }

  /**
   * Hides settings tooltip with manual triggers
   * @param tooltip - Settings tooltip reference id
   */
  hideSettingsTooltip(tooltip: { close: () => void }) {
    setTimeout(() => {
      if (!this.isSettingsTooltipHovered) {
        this.tooltips.opened.settings = false;
        tooltip.close();
        this.tooltips.languageTooltipOpened = false;
      }
    }, 300);
  }

  /**
   * Displays the respective menu's tooltip on hover event of menu
   * @param menu - Selected menu like `employees`, `signatures`, `campaigns`..
   */
  showTooltip(menu: string): void {
    let message = '';
    switch (menu) {
      case 'campaigns': {
        message = 'dc_d0003';
        break;
      }
      case 'guide': {
        message = 'guide';
        break;
      }
      case 'employees': {
        message = 'company';
        break;
      }
      case 'integrations': {
        message = 'nav_integrations';
        break;
      }
      case 'news': {
        message = 'nav_news';
        break;
      }
      case 'settings': {
        message = 'int_option_3';
        break;
      }
      case 'signatures': {
        message = 'SIGNATURES';
        break;
      }
    }
    this.tooltips.opened[menu] = true;
    this.tooltips.message = message;
  }

  /**
   * Closes submenu sidebar
   * @param submenuLocked - Submenu item is locked
   * @param isClickOutside - Boolean to know click outside
   */
  closeSubmenuSidebar(submenuLocked: boolean, isClickOutside = false): void {
    if (!submenuLocked) {
      if (isClickOutside) {
        this.updateSubmenuStatus(false, '');
        this.submenuDropdownOpened = false;
      }
    }
  }

  /**
   * The method will set the toggle lock for submenu
   * @param submenuLocked - Submenu item is locked
   */
  toggleSubmenuLock(submenuLocked: boolean): void {
    this.navigationSidebarService.setMenuLocked(!submenuLocked);
  }

  /**
   * Update the dropdown status of submenu as a boolean value
   */
  toggleSubmenuDropdown(): void {
    this.submenuDropdownOpened = !this.submenuDropdownOpened;
  }

  /**
   * Show/Hide setting language tooltip
   */
  showHideLanguageSubtooltip(): void {
    this.tooltips.languageTooltipOpened = !this.tooltips.languageTooltipOpened;
  }

  /**
   * Show/Hide setting tooltip
   * @param status - The status of settings tooltip
   */
  showHideSettingsTooltip(status: boolean): void {
    if (status == true) {
      this.tooltips.opened.settings = true;
    }
    this.isSettingsTooltipHovered = status;
  }

  /**
   * Hide the submenu based on menu click
   * @remarks menus will be `guide` or `integrations`
   */
  hideSubmenu(): void {
    this.navigationSidebarService.setSubMenuIsOpened(false);
  }

  /**
   * Logout from the application
   */
  logout(): void {
    this.navigationSidebarService.logout();
  }
}
