<ul
  class="mt-submenu-sidebar-link-wrapper mt-white sidebar-submenu"
  *transloco="let translate"
>
  <ng-container *ngIf="integrationsMenus$ | async as integrationsMenus">
    <ng-container *ngFor="let menu of integrationsMenus">
      <ng-container [ngSwitch]="menu.name">
        <div *ngSwitchCase="'software_and_downloads'">
          <li [routerLink]="menu.url" routerLinkActive="active">
            <a class="show">
              <ng-container
                [ngTemplateOutlet]="renderSideMenuData"
                [ngTemplateOutletContext]="{ menu }"
              ></ng-container>
            </a>
          </li>
        </div>

        <div
          *ngSwitchCase="'nav_integrations'"
          mt-claim-authorization
          [requiredClaim]="'UserSync:View'"
        >
          <li
            (click)="
              menus = navigationSidebarService.updateDropdownState(
                integrationsMenus,
                menu.name
              )
            "
          >
            <a class="show">
              <ng-container
                [ngTemplateOutlet]="renderSideMenuData"
                [ngTemplateOutletContext]="{ menu }"
              ></ng-container>
            </a>
          </li>
          <ng-container *ngIf="menu.isDropdownOpen">
            <li
              *ngFor="let subMenu of menu.submenu; let i = index"
              class="mt-submenu-title_subtitle-wrapper"
              [routerLink]="subMenu.url"
              routerLinkActive="active"
              [queryParams]="{
                type: subMenu.name
              }"
              (click)="
                navigationSidebarService._integrationOption.next(subMenu.name)
              "
            >
              <a class="show">
                <div class="mt-submenu-icon marleft5">
                  <mt-svg class="h12 mt-white" src="{{ subMenu.image }}">
                  </mt-svg>
                </div>
                <span class="mt-submenu-title_subtitle marleft5">
                  {{ translate(subMenu.name) }}</span
                >
              </a>
            </li>
          </ng-container>
        </div>

        <div
          *ngSwitchDefault
          mt-claim-authorization
          [requiredClaim]="'UserSync:View'"
        >
          <li
            *ngIf="
              !(activeIntegrationCount === 0 && menu.name === 'myintegrations')
            "
            [routerLink]="menu.url"
            [queryParams]="
              menu.name === 'myintegrations' ||
              menu.name === 'integration_list_office365' ||
              menu.name === 'source_gsuite'
                ? {
                    type: menu.name
                  }
                : null
            "
            (click)="
              menu.name !== 'integration_finder' &&
                navigationSidebarService._integrationOption.next(menu.name)
            "
            routerLinkActive="active"
          >
            <a class="show">
              <ng-container
                [ngTemplateOutlet]="renderSideMenuData"
                [ngTemplateOutletContext]="{ menu }"
              ></ng-container>
            </a>
          </li>
        </div>
      </ng-container> </ng-container
  ></ng-container>

  <!-- Displays sidemenu data -->
  <ng-template let-menu="menu" #renderSideMenuData>
    <div class="mt-submenu-icon" *ngIf="menu.image">
      <mt-svg class="mt-white" src="{{ menu.image }}"> </mt-svg>
    </div>
    <span class="mt-submenu-title inline-block">{{
      translate(menu.name)
    }}</span>
    <mt-svg
      *ngIf="menu.submenu"
      class="mt-submenu-dropdown-arrow martop4 mt-white"
      src="assets/images/sidebar/down-triangle.svg"
    >
    </mt-svg>
  </ng-template>
</ul>
