<!-- Cogsig header -->
<ng-container *ngIf="isGuideExist && !isGuidePage">
  <div class="mainwrapper" *transloco="let translate">
    <div class="topinfobanner flex-space-between green top-info-banner">
      <!-- Guide tour for cogsig -->
      <div class="flex-center-left trialinfoarea">
        <div class="infobanner-guide-text few-clicks-away">
          {{ translate('few_clicks_away') }}
        </div>
      </div>

      <div class="flex-center-right">
        <!-- free trial version has expired -->
        <span
          class="mt-weis cursor-pointer marright25 infobanner-guide-button guide-btn cursor-pointer"
          [routerLink]="['/getstartedguide']"
          >{{ translate('get_started') }}</span
        >
      </div>
    </div>
  </div>
</ng-container>
