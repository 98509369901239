<!-- Settings submenu section -->
<ul
  class="mt-submenu-sidebar-link-wrapper mt-white sidebar-submenu"
  *transloco="let translate"
>
  @if (settingsMenu$ | async; as settingsMenu) {
    @for (menu of settingsMenu; track menu.name) {
      @switch (menu.name) {
        @case ('administration') {
          <li
            mt-claim-authorization
            [requiredClaim]="'Administration:Manage'"
            (click)="
              menus = navigationSidebarService.updateDropdownState(
                settingsMenu,
                menu.name
              )
            "
          >
            <a
              [routerLink]="menu.url"
              routerLinkActive="active"
              class="show"
              [ngClass]="{
                link__inactive:
                  (menu.name
                  | submenuInactive
                    : authorizationScope)
              }"
            >
              <ng-container
                [ngTemplateOutlet]="renderSideMenuData"
                [ngTemplateOutletContext]="{ menu }"
              />
            </a>
          </li>
        }

        @case ('nav_plan_and_billing') {
          @if(((forceAllow && hasChargifySubscription) || ( !forceAllow && hasChargifySubscription ) || (!forceAllow && !hasChargifySubscription))) {
          <div mt-claim-authorization [requiredClaims]="['Billing:View']">
            @if ( menu.name ) {
              <li
                (click)="
                  menus = navigationSidebarService.updateDropdownState(
                    settingsMenu,
                    menu.name
                  )
                "
              >
                <a
                  class="show"
                  [ngClass]="{
                    link__inactive:
                      (menu.name
                      | submenuInactive
                        : authorizationScope)
                  }"
                >
                  <ng-container
                    [ngTemplateOutlet]="renderSideMenuData"
                    [ngTemplateOutletContext]="{ menu }"
                  />
                </a>
              </li>
            }

            @if (
              menu.isDropdownOpen &&
              (menu.name)
            ) {
              @for (subMenu of menu.submenu; track subMenu.name) {
                @if (
                  subMenu.name &&  ((subMenu.showOnFreeTrial && ((subMenu.name === 'bookSUBSCRIPTION' && !hasSubscription ) || (subMenu.name !== 'bookSUBSCRIPTION'))) || (!subMenu.showOnFreeTrial && hasSubscription)))
                  {
                  <li class="mt-submenu-title_subtitle-wrapper">
                    <a
                      [routerLink]="subMenu.url"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      class="show"
                    >
                      <div class="mt-submenu-icon marleft5">
                        <mt-svg
                          class="h12 mt-white"
                          src="{{ subMenu.image }}"
                        />
                      </div>
                      <span class="mt-submenu-title_subtitle marleft5">{{
                        translate(subMenu.name)
                      }}</span>
                    </a>
                  </li>
                }
              }
            }
            </div>
          }
        }

        @case ('advancedsettings') {
          <div mt-claim-authorization [requiredClaim]="'Settings:Manage'">
            @if (menu.name | displayMenuByPlan: hasSubscription || false) {
              <li
                (click)="
                  menus = navigationSidebarService.updateDropdownState(
                    settingsMenu,
                    menu.name
                  )
                "
              >
                <a
                  class="show"
                  [ngClass]="{
                    link__inactive:
                      (menu.name
                      | submenuInactive
                        : authorizationScope)
                  }"
                >
                  <ng-container
                    [ngTemplateOutlet]="renderSideMenuData"
                    [ngTemplateOutletContext]="{ menu }"
                  />
                </a>
              </li>
            }

            @if (
              menu.isDropdownOpen &&
              (menu.name | displayMenuByPlan: hasSubscription || false)
            ) {
              @for (subMenu of menu.submenu; track subMenu.name) {
                @if (
                  subMenu.name
                    | displayMenuByPlan: authorizationScope.isUserOnTargetPlan
                ) {
                  <li class="mt-submenu-title_subtitle-wrapper">
                    <a
                      [routerLink]="subMenu.url"
                      routerLinkActive="active"
                      class="show"
                    >
                      <div class="mt-submenu-icon marleft5">
                        <mt-svg
                          class="h12 mt-white"
                          src="{{ subMenu.image }}"
                        />
                      </div>
                      <span class="mt-submenu-title_subtitle marleft5">{{
                        translate(subMenu.name)
                      }}</span>
                    </a>
                  </li>
                }
              }
            }
          </div>
        }
      }
    }
  }

  <!-- Displayed submenu name which has no dropdown options  -->
  <ng-template let-menu="menu" #renderSideMenuData>
    <div class="mt-submenu-icon">
      <mt-svg
        [ngClass]="
          (menu.name
          | submenuInactive
            : authorizationScope)
            ? 'mt-grau'
            : 'mt-white'
        "
        src="{{ menu.image }}"
      >
      </mt-svg>
    </div>
    <span class="mt-submenu-title inline-block ccccc">{{
      translate(menu.name)
    }}</span>
    @if (menu.submenu && menu.submenu.length) {
      <mt-svg
        class="mt-submenu-dropdown-arrow martop4 mt-white"
        src="assets/images/sidebar/down-triangle.svg"
      />
    }
  </ng-template>
</ul>
