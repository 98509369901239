import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { CookiesBannerComponent } from './cookies-banner.component';

@NgModule({
  declarations: [CookiesBannerComponent],
  imports: [CommonModule, TranslocoModule],
  exports: [CookiesBannerComponent]
})
export class MtCookiesBannerModule {}
