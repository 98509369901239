import { Pipe, PipeTransform } from '@angular/core';

/**
 * Show/Hide menu based on cogsig users
 * @param menuName - The menu
 * @param planStatus - The status of user plan
 * @returns The status of display the menu
 */
@Pipe({
  name: 'displayMenuByPlan'
})
export class DisplayMenuByPlanPipe implements PipeTransform {
  transform(menuName: string, planStatus: boolean): boolean {
    return !(
      !planStatus &&
      (menuName === 'adv_stngs_internal_domains_hl' ||
        menuName === 'tracking' ||
        menuName === 'prio_headline' ||
        menuName === 'nav_plan_and_billing')
    );
  }
}
