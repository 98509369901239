<div class="tooltip-divider"></div>
<div class="accounts superadmin-accounts" *transloco="let translate">
  <div class="flex-center-space-between">
    <div class="small-title">{{ translate('access_customer_acc') }}</div>
  </div>

  <div
    class="flex-center-space-between"
    *ngIf="selectedCustomerEmail$ | async as customerEmail"
  >
    <span class="martop5 customer-list">
      <div class="btn-group account-select full-width">
        <div ngbDropdown class="d-block" #myDrop="ngbDropdown">
          <button
            class="btn btn-outline-primary customer-dropdown customer-email-tooltip"
            ngbDropdownToggle
          >
            <ng-container
              *ngIf="
                superAdminAccountData.email !== customerEmail;
                else selectEmail
              "
              [ngTemplateOutlet]="
                customerEmail.length < 23
                  ? displayOnlyEmail
                  : displayWithTooltip
              "
              [ngTemplateOutletContext]="{
                email: customerEmail,
                stringLimit: 22
              }"
            >
            </ng-container>
            <ng-template #selectEmail>
              <span>{{ translate('select_email') }}</span>
            </ng-template>
          </button>
          <ul
            ngbDropdownMenu
            class="dropdown-menu-left full-width force-scroll"
          >
            <input
              type="text"
              [formControl]="searchCustomerEmail"
              placeholder="{{ translate('search_with_minimum_3_character') }}"
              class="form-control menu-left-input"
            />
            <ng-container
              *ngIf="
                filteredCustomerAccounts$ | async as filteredCustomerAccounts
              "
            >
              <li
                *ngFor="let data of allCustomers"
                (click)="selectedCustomerEmail = data.email; myDrop.close()"
              >
                <a class="customer-email-tooltip">
                  <ng-container
                    [ngTemplateOutlet]="
                      data.email.length < 24
                        ? displayOnlyEmail
                        : displayWithTooltip
                    "
                    [ngTemplateOutletContext]="{
                      email: data.email,
                      stringLimit: 23
                    }"
                  >
                  </ng-container>
                </a>
              </li>
              <li
                class="txt-gray"
                *ngIf="searchCustomerEmail.value && allCustomers.length === 0"
              >
                <a>{{ translate('account_not_found') }} </a>
              </li>
            </ng-container>
          </ul>
        </div>
      </div></span
    >

    <!-- Play button to login into the customer account -->
    <button
      class="play-btn super-admin-btn"
      (click)="emitLoginData(customerEmail)"
      [disabled]="customerEmail === accountData.email"
    >
      <mt-svg
        class="rignt-triangle mt-tooltip-menu-icon mt-tooltip-menu-icon_arrow sidebar-languages-right-triangle mt-white"
        src="assets/images/sidebar/right-triangle.svg"
      >
      </mt-svg>
    </button>
  </div>

  <!-- Recent customer logins -->
  <div *ngIf="recentCustomersEmail.length > 0">
    <div class="small-title">{{ translate('recently_viewed') }}</div>
    <div
      *ngFor="let email of recentCustomersEmail"
      class="recent-customers-email customer-email-tooltip"
      (click)="
        customerLogin.emit({
          customerEmail: email,
          isSuperAdminLogin: false
        });
        adminAccountsTooltip.emit()
      "
    >
      <ng-container
        [ngTemplateOutlet]="
          email.length < 31 ? displayOnlyEmail : displayWithTooltip
        "
        [ngTemplateOutletContext]="{ email, stringLimit:30}"
      >
      </ng-container>
    </div>

    <!-- Back to my account button -->
    <mt-button
      class="roboto_light martop15"
      color="primary"
      text="back_to_my_account"
      [disabled]="superAdminAccountData.email === accountData.email"
      (click)="
        customerLogin.emit({
          customerEmail: superAdminAccountData.email,
          isSuperAdminLogin: true
        });
        adminAccountsTooltip.emit()
      "
    ></mt-button>
  </div>
</div>

<ng-template let-email="email" #displayOnlyEmail>
  <span>{{ email }}</span>
</ng-template>

<ng-template
  let-email="email"
  let-stringLimit="stringLimit"
  #displayWithTooltip
>
  <span [ngbTooltip]="email" tooltipClass="inner_tooltip">{{
    email | textTruncate : stringLimit
  }}</span>
</ng-template>
