import { NavigationSidebarMenu } from 'src/app/modules/root/model/navigation-sidebar-menu.interface';

const TOP_MENUS: NavigationSidebarMenu[] = [
  { image: 'assets/images/sidebar/employees.svg', name: 'employees', url: '/employees/list' },
  { image: 'assets/images/sidebar/signatures.svg', name: 'signatures', url: '/signatures/list' },
  { image: 'assets/images/sidebar/campaign.svg', name: 'campaigns', url: '/campaigns/list' }
];

const BOTTOM_MENU: NavigationSidebarMenu[] = [
  { image: 'assets/images/sidebar/guide.svg', name: 'guide', url: '/getstartedguide' },
  { image: 'assets/images/sidebar/integrations.svg', name: 'integrations', url: '/integrations/filter' },
  { image: 'assets/images/sidebar/news.svg', name: 'news', url: '/' } // TODO: news component url needs to be created
];

const SETTINGS_MIDDLE_MENU: NavigationSidebarMenu[] = [
  { name: 'nav_help_center', url: 'sidebar_settings_help' },
  { name: 'resources', url: 'sidebar_settings_resources' },
  { name: 'imprint', url: 'sidebar_settings_imprint' }
];

const SUBMENU: NavigationSidebarMenu[] = [
  { name: 'employees', title: 'company' },
  { name: 'signatures', title: 'SIGNATURES' },
  { name: 'campaigns', title: 'dc_d0003' },
  { name: 'settings', title: 'int_option_3' },
  { name: 'integrations', title: 'nav_integrations' }
];

export const SIDEBAR_MENU = {
  bottomMenus: BOTTOM_MENU,
  settings_middle: SETTINGS_MIDDLE_MENU,
  submenus: SUBMENU,
  topMenus: TOP_MENUS
};
