import { Component, ChangeDetectionStrategy, Input, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Interfaces
import { IntegrationTypes } from '@model/interfaces/integrations.interface';
import { NavigationSidebarMenu } from 'src/app/modules/root/model/navigation-sidebar-menu.interface';

// Services
import { NavigationSidebarService } from 'src/app/services/navigation-sidebar/navigation-sidebar.service';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { MtSvgComponent } from '@shared/components/atoms/svg/mt-svg.component';
import { TranslocoModule } from '@jsverse/transloco';
import { ClaimAuthorizationDirective } from '@shared/directives/claim-authorization/claim-authorization.directive';

const EMPLOYEES_MENU: NavigationSidebarMenu[] = [
  {
    image: 'assets/images/sidebar/employees.svg',
    name: 'EMPLOYEE',
    isDropdownOpen: true,
    submenu: [
      {
        image: 'assets/images/sidebar/employees.svg',
        name: 'all-employees',
        url: '/employees/list',
        hasCounter: true
      },
      {
        image: 'assets/images/sidebar/employees.svg',
        name: 'broken_sync_menupoint',
        url: '/employees/outdated',
        hasCounter: true
      },
      {
        image: 'assets/images/sidebar/employees.svg',
        name: 'available-from-directory',
        url: '/employees/not-yet-imported',
        hasCounter: false
      }
    ]
  },
  {
    image: 'assets/images/sidebar/folder.svg',
    name: 'DEPARTMENTS',
    url: '/groups/list',
    hasCounter: true
  },
  {
    image: 'assets/images/sidebar/document.svg',
    name: 'datasets',
    isDropdownOpen: false,
    submenu: [
      { image: 'assets/images/sidebar/accounts.svg', name: 'Employee', url: '/dataprofiles/employee' },
      { image: 'assets/images/sidebar/account.svg', name: 'company', url: 'dataprofiles/company' }
    ]
  }
];

@Component({
  selector: 'mt-navigation-sidebar-employee-submenu',
  templateUrl: './navigation-sidebar-employee-submenu.component.html',
  styleUrls: ['./navigation-sidebar-employee-submenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, RouterLink, MtSvgComponent, TranslocoModule, ClaimAuthorizationDirective]
})
export class NavigationSidebarEmployeeSubmenuComponent {
  navigationSidebarService = inject(NavigationSidebarService);

  private _employeeMenus$ = new BehaviorSubject(EMPLOYEES_MENU);

  showNotYetImported = false;

  /**
   * Determines the count of all employees
   * @defaultValue 0
   */
  @Input() allEmployeeCount = 0;

  /**
   * Determines the count of outdated employees
   * @defaultValue 0
   */
  @Input() outdatedEmployeeCount = 0;

  /**
   * Determines the counte of group
   * @defaultValue 0
   */
  @Input() groupCount = 0;

  /**
   * Determines to available office365 or gSuite connection
   */
  @Input() set myIntegrations(val: IntegrationTypes[]) {
    const hasIntegration = val.some(
      integration => integration.title === 'Google_headline' || integration.title === 'Microsoft_headline'
    );

    this.showNotYetImported = hasIntegration;
  }

  /**
   * Emits the employee menu items
   */
  employeeMenus$ = this._employeeMenus$.asObservable();

  /**
   * Sets new menu items
   * @param menuItems - New menu items to set
   */
  set menus(menuItems: NavigationSidebarMenu[]) {
    this._employeeMenus$.next(menuItems);
  }
}
