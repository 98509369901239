import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mt-cookies-banner',
  templateUrl: './cookies-banner.component.html',
  styleUrls: ['./cookies-banner.component.scss']
})
export class CookiesBannerComponent implements OnInit {
  showCookiesBanner = true;

  ngOnInit(): void {
    const match = document.cookie.match(new RegExp('(^| )we-love-cookies=([^;]+)'));
    if (match) {
      this.showCookiesBanner = false;
    }
  }

  /**
   * Set cookies for whole application
   */
  agreedCookies(): void {
    const date = new Date();
    const time = date.getTime();
    // Default at 365 days.
    const days = 365;
    // Get unix milliseconds at current time plus number of days
    date.setTime(time + days * 86400000); //24 * 60 * 60 * 1000
    document.cookie = `we-love-cookies=1; expires=' ${date.toUTCString()}  '; path=/`;
    this.showCookiesBanner = false;
  }
}
