<!-- Mailtastic header -->
<ng-container>
  <div class="mainwrapper topinfo-banner">
    <div
      class="topinfobanner flex-center-space-between"
      [ngClass]="
        !forceAllow && !hasSubscription && !hasTestTime ? 'red' : 'green'
      "
      *ngIf="
        !hasSubscription ||
        !isUserFromAngularTs ||
        (adminAccounts && adminAccounts.length > 0)
      "
    >
      <div class="flex-center-left trialinfoarea">
        <ng-container *ngIf="!forceAllow && !hasSubscription">
          <!-- Upgrade now header -->
          <ng-container *ngIf="hasTestTime; else hasNotTestTime">
            <span
              class="font-weight-bold"
              [innerHtml]="
                'param.bar_trial_active_info'
                  | transloco : { value: trialDaysLeft }
              "
            ></span>
            <a
              class="font-weight-bold marleft5 text-underline-imp color-inherit"
              [routerLink]="['/booking']"
              transloco="bar_beta_active_btn"
            ></a>
          </ng-container>
          <!-- free trial version has expired -->
          <ng-template #hasNotTestTime>
            <span
              class="mt-weis font-weight-bold"
              transloco="bar_trial_expired_info"
            ></span>
            <a
              class="cursor-pointer marleft5 text-underline-imp mt-weis"
              transloco="bar_beta_expired_btn"
              [routerLink]="['/dashboard']"
            ></a>
          </ng-template>
        </ng-container>
      </div>

      <div class="flex-center-right">
        <!-- free trial version has expired -->
        <ng-container *ngIf="!forceAllow && !hasSubscription && !hasTestTime">
          <span
            class="mt-weis cursor-pointer marright25"
            transloco="logout"
            (click)="logout.emit()"
          ></span>
        </ng-container>
      </div>

      <!-- External admin connected accounts -->
      <div
        data-hj-suppress
        *ngIf="adminAccounts && adminAccounts.length > 0"
        data-cy="external-admin"
        class="topinfobanner flex-center-right dark-blue connected-accounts"
      >
        <div
          data-cy="external-admin-dropdown"
          class="flex-center-left selector cursor-pointer"
          placement="bottom"
          tooltipClass="mt-menu-tooltip-wrapper connected_account bottom"
          [autoClose]="'outside'"
          [ngbTooltip]="mtExternalAdminTooltip"
          triggers="manual"
          #adminAccountsTooltip="ngbTooltip"
          (click)="adminAccountsTooltip.open()"
        >
          <span
            data-cy="selected-company-name"
            class="selected-company"
            [ngbTooltip]="
              accountData.companyName.length > 23 ? accountData.companyName : ''
            "
            *ngIf="accountData.companyName"
            placement="left"
            >{{ accountData.companyName | textTruncate : 23 }}</span
          >
          <mt-svg class="mt-white menu" src="assets/images/svgs/menu.svg">
          </mt-svg>
        </div>
      </div>

      <ng-template #mtExternalAdminTooltip>
        <div class="flex-center-space-between user-desc" data-cy="tooltip-info">
          <div class="profile-image" data-cy="profile-icon">
            <span class="profile_circle">{{ accountData | shortName }}</span>
          </div>
          <div class="user-data" data-cy="user-data">
            <div class="name" data-cy="user-name">
              {{ accountData.firstName }} {{ accountData.lastName }}
            </div>
            <div
              placement="left"
              class="email"
              [ngbTooltip]="
                accountData.email.length > 28 ? accountData.email : ''
              "
              tooltipClass="inner_tooltip"
              data-cy="user-email"
            >
              {{ accountData.email | textTruncate : 28 }}
            </div>
            <div class="edit martop20 active">
              <span
                data-cy="administrator"
                class="cursor-pointer"
                transloco="edit_profile"
                [routerLink]="['/settings/administration']"
              ></span>
            </div>
          </div>
        </div>

        <!-- Super Admin Account -->
        <div *ngIf="superAdminAccountData.isSuperAdmin">
          <mt-top-info-banner-super-admin
            [allCustomers]="allCustomers"
            [superAdminAccountData]="superAdminAccountData"
            [accountData]="accountData"
            [recentCustomersEmail]="recentCustomersEmail"
            (searchValue)="searchValue.emit($event)"
            (adminAccountsTooltip)="adminAccountsTooltip.close()"
            (customerLogin)="customerLogin.emit($event)"
          ></mt-top-info-banner-super-admin>
        </div>

        <!-- Admin Accounts -->
        <div class="tooltip-divider"></div>
        <div class="accounts" data-cy="accounts">
          <div class="flex-center-space-between">
            <div class="small-title" transloco="accounts"></div>
            <div
              data-cy="view-all"
              class="small-title text-underline cursor-pointer"
              transloco="view_all"
              [routerLink]="['/account/select']"
            ></div>
          </div>
          <div class="btn-group account-select full-width leftbutton">
            <div
              ngbDropdown
              class="d-block"
              #myDrop="ngbDropdown"
              data-cy="accounts-dropdown"
            >
              <button class="btn btn-outline-primary" ngbDropdownToggle>
                <span
                  *ngIf="accountData.companyName"
                  [ngbTooltip]="
                    accountData.companyName.length > 23
                      ? accountData.companyName
                      : ''
                  "
                  tooltipClass="inner_tooltip"
                >
                  {{ accountData.companyName | textTruncate : 23 }}</span
                >
              </button>
              <ul
                ngbDropdownMenu
                class="dropdown-menu-left full-width force-scroll"
              >
                <input
                  data-cy="search-account"
                  type="text"
                  [formControl]="searchTxt"
                  placeholder="{{ 'Search' | transloco }}"
                  class="form-control menu-left-input"
                />
                <ng-container
                  *ngIf="filteredAccounts$ | async as filteredAccounts"
                >
                  <li
                    *ngFor="let data of filteredAccounts"
                    (click)="
                      changeAccount.emit(data.id);
                      myDrop.close();
                      adminAccountsTooltip.close()
                    "
                  >
                    <a
                      [ngbTooltip]="
                        data.companyName.length > 23 ? data.companyName : ''
                      "
                      tooltipClass="inner_tooltip"
                      >{{ data.companyName | textTruncate : 23 }}</a
                    >
                  </li>
                  <li class="txt-gray" *ngIf="filteredAccounts.length === 0">
                    <a
                      transloco="account_not_found"
                      data-cy="account-not-found"
                    >
                    </a>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>

        <!-- Logout -->
        <div class="tooltip-divider"></div>
        <div class="logout">
          <span
            data-cy="logout"
            (click)="logout.emit()"
            class="cursor-pointer"
            transloco="logout"
          ></span>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
