import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NavigationSidebarMenu } from '@modules/root/model/navigation-sidebar-menu.interface';

const SIGNATURES_MENU: NavigationSidebarMenu[] = [
  { hasCounter: true, image: 'assets/images/sidebar/signatures.svg', name: 'SIGNATURES', url: '/signatures/list' },
  { image: 'assets/images/sidebar/settings.svg', name: 'Default_Signatures', url: '/signatures/default-signatures' }
];

@Component({
  selector: 'mt-navigation-sidebar-signature-submenu',
  templateUrl: './navigation-sidebar-signature-submenu.component.html',
  styleUrls: ['./navigation-sidebar-signature-submenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationSidebarSignatureSubmenuComponent {
  /**
   * Determines the counter of signatures
   * @defaultValue 0
   */
  @Input() signatureCount = 0;

  /**
   * The menus of signature
   */
  readonly menus = SIGNATURES_MENU;
}
