import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, take, map } from 'rxjs';
import { NavigationSidebarService } from 'src/app/services/navigation-sidebar/navigation-sidebar.service';
import { environment } from 'src/environments/environment';

/**
 * If mailtastic env and user dont have subscription and expired trial version then
 * Restict on navigation
 */
@Injectable({
  providedIn: 'root'
})
export class SubscriptionGuard implements CanActivate {
  constructor(private navigationSidebarService: NavigationSidebarService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const isCogsig = environment.isCogSig;
    if (isCogsig) {
      return true;
    } else {
      return this.navigationSidebarService.hasSubscription$.pipe(
        take(1),
        map(data => {
          if (data.hasSubscription || data.hasTestTime || data.forceAllow) {
            return true;
          } else {
            // Allow dashboard and booking page to display expiration and purchase subscription stuff
            const allowedURLs = ['/dashboard', '/booking'];

            // Redirect other URL's
            if (!allowedURLs.includes(state.url)) {
              void this.router.navigate(['/dashboard']);
            }

            return allowedURLs.includes(state.url);
          }
        })
      );
    }
  }
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route, state);
  }
}
