import { BehaviorSubject } from 'rxjs';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { ClaimAuthorizationDirective } from '@shared/directives/claim-authorization/claim-authorization.directive';
import { CommonModule } from '@angular/common';
import { IMAILTASTIC_AUTHORIZATION_SCOPE } from '@services/account/account-service.interface';
import { MtSvgComponent } from '@shared/components/atoms/svg/mt-svg.component';
import { NavigationSidebarMenu } from '@modules/root/model/navigation-sidebar-menu.interface';
import { NavigationSidebarService } from '@services/navigation-sidebar/navigation-sidebar.service';
import { RouterLink } from '@angular/router';
import { SubmenuInactivePipe } from '@modules/root/pipes/submenu-inactive/submenu-inactive.pipe';
import { TranslocoModule } from '@jsverse/transloco';

const MARKETING_MENUS: NavigationSidebarMenu[] = [
  { hasCounter: true, image: 'assets/images/sidebar/campaign.svg', name: 'CAMPAIGNS', url: '/campaigns/list' },
  { hasCounter: true, image: 'assets/images/sidebar/events.svg', name: 'events', url: '/events' },
  { image: 'assets/images/sidebar/graph.svg', name: 'navi_dashboard_overview', url: '/dashboard/overview' },
  {
    hasCounter: true,
    image: 'assets/images/svgs/targetgroupsign2.svg',
    name: 'target_group_s',
    url: '/targetgroups'
  },
  {
    hasCounter: true,
    image: 'assets/images/svgs/notification.svg',
    name: 'notifications',
    url: '/notifications/list'
  },
  { image: 'assets/images/svgs/Upload.svg', name: 'performance_feed', url: '/performancefeed/list' },
  {
    image: 'assets/images/sidebar/employees.svg',
    name: 'recipients',
    isDropdownOpen: false,
    submenu: [
      { image: 'assets/images/sidebar/accounts.svg', name: 'accounts', url: 'recipients/accounts/list' },
      { image: 'assets/images/sidebar/account.svg', name: 'contacts', url: 'recipients/contacts/list' }
    ]
  },
  {
    image: 'assets/images/sidebar/leads.svg',
    name: 'Leads',
    isDropdownOpen: false,
    submenu: [
      { image: 'assets/images/sidebar/accounts.svg', name: 'accounts', url: 'leads/accounts/list' },
      { image: 'assets/images/sidebar/account.svg', name: 'contacts', url: 'leads/contacts/list' }
    ]
  }
];

@Component({
  selector: 'mt-navigation-sidebar-marketing-submenu',
  templateUrl: './navigation-sidebar-marketing-submenu.component.html',
  styleUrls: ['./navigation-sidebar-marketing-submenu.component.scss'],
  standalone: true,
  imports: [
    ClaimAuthorizationDirective,
    CommonModule,
    MtSvgComponent,
    RouterLink,
    SubmenuInactivePipe,
    TranslocoModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationSidebarMarketingSubmenuComponent {
  navigationSidebarService = inject(NavigationSidebarService);

  /**
   * Holds the current marketing menu state
   */
  private _marketingMenus$ = new BehaviorSubject(MARKETING_MENUS);

  /**
   * Get authorization scope to identify the plan information
   */
  @Input() authorizationScope!: IMAILTASTIC_AUTHORIZATION_SCOPE;

  /**
   * Determines the count of campaign
   * @defaultValue 0
   */
  @Input() campaignCount = 0;

  /**
   * Determines the count of event
   * @defaultValue 0
   */
  @Input() eventCount = 0;

  /**
   * Determines the count of target group
   * @defaultValue 0
   */
  @Input() targetGroupCount = 0;

  /**
   * Determines the count of notifications
   * @defaultValue 0
   */
  @Input() notificationCount = 0;

  /**
   * Emits the current marketing menu state
   */
  marketingMenus$ = this._marketingMenus$.asObservable();

  /**
   * Sets new menu items
   * @param menuItems - New menu items to set
   */
  set menus(menuItems: NavigationSidebarMenu[]) {
    this._marketingMenus$.next(menuItems);
  }
}
