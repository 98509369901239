<div *ngIf="isSignupPage$ | async as page">
  <div
    class="no-mobile-view"
    [ngClass]="page.isSignupPage ? 'inSignupPage' : 'outOfSignupPage'"
  >
    <div class="img-mobile">
      <h3
        transloco="cogsig-login-mobile"
        *ngIf="isCogsig; else mailtasticNoMobileView"
      ></h3>
      <ng-template #mailtasticNoMobileView
        ><h3 transloco="mailtastic-login-mobile"></h3
      ></ng-template>
      <div class="img-mobile-wrapper">
        <img
          src="assets/images/login/cognism_shapes_explosion.svg"
          alt="Mailtastic"
        />
    </div>
    </div>
  </div>
</div>
