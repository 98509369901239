import { Pipe, PipeTransform } from '@angular/core';

/**
 * Determines if a particular sidebar menu entry should be shown or not
 *
 * @param menu - Menu name
 * @param isCogSig - Is the environment CogSig?
 *
 * @returns Boolean indicating if menu should be shown
 */
@Pipe({
  name: 'isMenuShown'
})
export class IsMenuShownPipe implements PipeTransform {
  transform(menu: string, isCogSig: boolean): boolean {
    if (menu === 'news' || (!isCogSig && menu === 'guide')) {
      return false;
    }

    return true;
  }
}
