import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'mt-navigation-sidebar-menu-header',
  templateUrl: './navigation-sidebar-menu-header.component.html',
  styleUrls: ['./navigation-sidebar-menu-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationSidebarMenuHeaderComponent {
  /**
   * Environment variable
   */
  isCognismSignatures = environment.isCogSig;

  /**
   * Get class name
   * @defaultValue ''
   */
  @Input() className = '';

  /**
   * Trigger employee form value
   */
  @Output() closeSubmenuSidebar = new EventEmitter<void>();
}
