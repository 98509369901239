import { Component } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { filter, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'mt-no-mobile-view',
  templateUrl: './no-mobile-view.component.html',
  styleUrls: ['./no-mobile-view.component.scss']
})
export class NoMobileViewComponent {
  isCogsig = environment.isCogSig;

  constructor(private router: Router) {}

  /**
   * Gets the signup page trigger
   */
  isSignupPage$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    map((event: Event) => {
      if (event instanceof NavigationEnd) {
        return { isSignupPage: event.url.includes('/signup') };
      }
      return { isSignupPage: false };
    })
  );
}
