import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { NavigationSidebarMenu } from '@modules/root/model/navigation-sidebar-menu.interface';
import { INavigationMenu } from 'src/app/modules/root/model/navigation-sidebar-menu.interface';

@Component({
  selector: 'mt-navigation-sidebar-main-menus',
  templateUrl: './navigation-sidebar-main-menus.component.html',
  styleUrls: ['./navigation-sidebar-main-menus.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationSidebarMainMenusComponent {
  private router = inject(Router);

  /**
   * Environment variable
   */
  isCognismSignatures = environment.isCogSig;

  /**
   * Used to hold that guide menu is hidden in sidebar
   * @defaultValue false
   */
  @Input() isGuideMenuHidden = false;

  /**
   * First three menus of sidebar section including company, signatures and marketings
   */
  @Input() topMenus!: NavigationSidebarMenu[];

  /**
   * Bottom three menus of sidebar section including integrations, guide, news and settings
   */
  @Input() bottomMenus!: NavigationSidebarMenu[];

  /**
   * The active route name
   */
  @Input() activeRoute!: string;

  /**
   * The tooltip data
   */
  @Input() tooltips!: {
    languageTooltipOpened: boolean;
    message: string;
    opened: INavigationMenu;
  };

  /**
   * The subemenu section name
   */
  @Input() submenuSection!: string;

  /**
   * The check of submenu opened or not
   * @defaultValue false
   */
  @Input() submenuOpened = false;

  /**
   * The route string
   */
  @Input() routeString!: string;

  /**
   * The user basic plan status
   * @defaultValue `false`
   */
  @Input() isUserOnBasicPlan = false;

  /**
   * Trigger of show tooltip
   */
  @Output() showTooltip = new EventEmitter<string>();

  /**
   * Trigger of hide tooltip
   */
  @Output() hideTooltip = new EventEmitter<string>();

  /**
   * Change the status of opened submenu
   */
  @Output() toggleSubmenuSidebar = new EventEmitter<string>();

  /**
   * Hides the submenu
   */
  @Output() hideSubmenu = new EventEmitter<void>();

  /**
   * Shows the settings tooltip as template with menu names
   */
  @Output() showSettingsTooltip = new EventEmitter<{ open: () => void }>();

  /**
   * Hides the settings tooltip as template with menu names
   */
  @Output() hideSettingsTooltip = new EventEmitter<{ close: () => void }>();

  /**
   * Shows the settings language tooltip
   */
  @Output() showHideLanguageSubtooltip = new EventEmitter<void>();

  /**
   * Hides the settings language tooltip
   */
  @Output() showHideSettingsTooltip = new EventEmitter<boolean>();

  /**
   * Checks if respective submenu of selected menu is opened
   * @param menu - The selected menu like `employees`, `campaigns`, `signatures`..
   * @param submenuOpened - Submenu item is opened
   * @returns The status of submenu section like it is opened or closed of selected menu as a boolean value
   */
  isSubmenuSectionActive(menu: string, submenuOpened: boolean): boolean {
    return submenuOpened && this.submenuSection === menu;
  }

  /**
   * Take the action when click on menu
   * @param menuName - The selected menu like `employees`, `campaigns`, `signatures`..
   */
  menuClick(menuName: string): void {
    this.hideTooltip.emit(menuName);
    if (menuName === 'guide') {
      this.hideSubmenu.emit();
      void this.router.navigate(['/getstartedguide']);
    } else {
      this.toggleSubmenuSidebar.emit(menuName);
    }
  }
}
