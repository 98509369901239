import { Pipe, PipeTransform } from '@angular/core';
import { IMAILTASTIC_AUTHORIZATION_SCOPE } from '@services/account/account-service.interface';

/**
 * Check feature exist on subscribed plan or not
 * To change style of submenu
 *
 * @param subMenuName - Submenu name from navigation sidebar
 *
 * @param authorizationScope - Authorization scope data
 *
 * @returns Boolean value based on the subscribed plan & submenu name
 */
@Pipe({
  name: 'submenuInactive',
  standalone: true
})
export class SubmenuInactivePipe implements PipeTransform {
  transform(subMenuName: string, authorizationScope: IMAILTASTIC_AUTHORIZATION_SCOPE): boolean {
    return (
      ((subMenuName === 'CAMPAIGNS' || subMenuName === 'events' || subMenuName === 'navi_dashboard_overview') &&
        authorizationScope.isUserOnBasicPlan) ||
      ((subMenuName === 'target_group_s' || subMenuName === 'recipients' || subMenuName === 'Leads') &&
        !authorizationScope.hasTargetLists) ||
      (subMenuName === 'notifications' && !authorizationScope.hasNotifs) ||
      (subMenuName === 'performance_feed' && !authorizationScope.hasActionfeed)
    );
  }
}
