import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IAccountData, SuperAdminAccountData } from '@model/interfaces/account-admin.interface';
import { CustomerEmails, CustomerLogin } from '@services/user/user-service.interface';
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter, tap } from 'rxjs';

@Component({
  selector: 'mt-top-info-banner-super-admin',
  templateUrl: './top-info-banner-super-admin.component.html',
  styleUrls: ['./top-info-banner-super-admin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopInfoBannerSuperAdminComponent {
  /**
   * The logged in user account data
   */
  private _accountData!: IAccountData;

  /**
   * The selected customer email
   */
  set selectedCustomerEmail(val: string) {
    this._selectedCustomerEmail$.next(val);
  }

  private _selectedCustomerEmail$ = new BehaviorSubject('');
  selectedCustomerEmail$ = this._selectedCustomerEmail$.asObservable();

  /**
   * Text input of customer email list
   */
  searchCustomerEmail = new FormControl<string>('', { nonNullable: true });

  /**
   * The email array of all customers
   * @defaultValue []
   */
  @Input() allCustomers: CustomerEmails[] = [];

  /**
   * The superadmin account data
   */
  @Input() superAdminAccountData!: SuperAdminAccountData;

  /**
   * The array of customer email
   * @defaultValue []
   */
  @Input() recentCustomersEmail: string[] = [];

  /**
   * The account data including company name, firstname, lastname and email
   */
  @Input() set accountData(val: IAccountData) {
    this.selectedCustomerEmail = val.email;
    this._accountData = val;
  }

  get accountData(): IAccountData {
    return this._accountData;
  }

  /**
   * Triggers customer login action
   */
  @Output() customerLogin = new EventEmitter<CustomerLogin>();

  /**
   * Close the tooltip
   */
  @Output() adminAccountsTooltip = new EventEmitter<void>();

  /**
   * Emits the search text/value of customer email
   */
  @Output() searchValue = new EventEmitter<string>();

  /**
   * Search customer email
   */
  filteredCustomerAccounts$ = this.searchCustomerEmail.valueChanges.pipe(
    filter(text => text.length > 2),
    debounceTime(500),
    distinctUntilChanged(),
    tap(text => this.searchValue.emit(text))
  );

  /**
   * Emit the login data and close the tooltip
   * @param email - The customer email address
   */
  emitLoginData(email: string): void {
    this.customerLogin.emit({
      customerEmail: email,
      isSuperAdminLogin: false
    });
    this.adminAccountsTooltip.emit();
    this.searchCustomerEmail.setValue('');
  }
}
