import { Pipe, PipeTransform } from '@angular/core';
import { IExternalAdminGetAccounts } from 'src/app/services/external-admin/external-admin.interface';

export interface NavbarTopAreaShown {
  hasSubscription: boolean;
  isUserFromAngularTs: boolean;
  accountData: {
    adminAccounts: IExternalAdminGetAccounts[];
  };
}

/**
 * Manage top padding
 * For new user signup with latest angular and signle account access then hide top bar
 * @param loadInfo - Object of NavbarTopAreaShown
 * @param isCognismSignatures - Is CogSig or not
 * @returns Boolean value to assign class or not
 */
@Pipe({
  name: 'topAreaShown'
})
export class TopAreaShownPipe implements PipeTransform {
  transform(loadInfo: NavbarTopAreaShown, isCognismSignatures: boolean): boolean {
    return (
      !isCognismSignatures &&
      (loadInfo.accountData.adminAccounts.length > 0 || !loadInfo.hasSubscription || !loadInfo.isUserFromAngularTs)
    );
  }
}
