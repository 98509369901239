<!-- Marketing submenu section -->
<ul
  class="mt-submenu-sidebar-link-wrapper mt-white sidebar-submenu"
  *transloco="let translate"
  data-cy="marketing-sidebar-submenu"
>
  <ng-container *ngIf="marketingMenus$ | async as marketingMenus">
    <ng-container *ngFor="let menu of marketingMenus">
      <ng-container [ngSwitch]="menu.name">
        <div
          *ngSwitchCase="'CAMPAIGNS'"
          mt-claim-authorization
          [requiredClaim]="'Campaign:View'"
        >
          <li
            (click)="
              menus = navigationSidebarService.updateDropdownState(
                marketingMenus,
                menu.name
              )
            "
          >
            <a
              [routerLink]="menu.url"
              routerLinkActive="active"
              class="show"
              [ngClass]="{
                link__inactive: (menu.name | submenuInactive: authorizationScope)
              }"
            >
              <ng-container
                [ngTemplateOutlet]="renderSideMenuData"
                [ngTemplateOutletContext]="{ menu }"
              ></ng-container>
            </a>
          </li>
        </div>

        <div
          *ngSwitchCase="'events'"
          mt-claim-authorization
          [requiredClaim]="'Event:View'"
        >
          <li
            (click)="
              menus = navigationSidebarService.updateDropdownState(
                marketingMenus,
                menu.name
              )
            "
          >
            <a
              [routerLink]="menu.url"
              routerLinkActive="active"
              class="show"
              [ngClass]="{
                link__inactive: (menu.name | submenuInactive: authorizationScope)
              }"
            >
              <ng-container
                [ngTemplateOutlet]="renderSideMenuData"
                [ngTemplateOutletContext]="{ menu }"
              ></ng-container>
            </a>
          </li>
        </div>

        <div
          *ngSwitchCase="'navi_dashboard_overview'"
          mt-claim-authorization
          [requiredClaim]="'Campaign:View'"
        >
          <li
            (click)="
              menus = navigationSidebarService.updateDropdownState(
                marketingMenus,
                menu.name
              )
            "
          >
            <a
              [routerLink]="menu.url"
              routerLinkActive="active"
              class="show"
              [ngClass]="{
                link__inactive: (menu.name | submenuInactive: authorizationScope)
              }"
            >
              <ng-container
                [ngTemplateOutlet]="renderSideMenuData"
                [ngTemplateOutletContext]="{ menu }"
              ></ng-container>
            </a>
          </li>
        </div>

        <div
          *ngSwitchCase="'target_group_s'"
          mt-claim-authorization
          [requiredClaim]="'TargetGroups:View'"
        >
          <li
            (click)="
              menus = navigationSidebarService.updateDropdownState(
                marketingMenus,
                menu.name
              )
            "
          >
            <a
              [routerLink]="menu.url"
              routerLinkActive="active"
              class="show"
              [ngClass]="{
                link__inactive: (menu.name | submenuInactive: authorizationScope)
              }"
            >
              <ng-container
                [ngTemplateOutlet]="renderSideMenuData"
                [ngTemplateOutletContext]="{ menu }"
              ></ng-container>
            </a>
          </li>
        </div>

        <div
          *ngSwitchCase="'notifications'"
          mt-claim-authorization
          [requiredClaim]="'Notification:View'"
        >
          <li
            (click)="
              menus = navigationSidebarService.updateDropdownState(
                marketingMenus,
                menu.name
              )
            "
          >
            <a
              [routerLink]="menu.url"
              routerLinkActive="active"
              class="show"
              [ngClass]="{
                link__inactive: (menu.name | submenuInactive: authorizationScope)
              }"
            >
              <ng-container
                [ngTemplateOutlet]="renderSideMenuData"
                [ngTemplateOutletContext]="{ menu }"
              ></ng-container>
            </a>
          </li>
        </div>

        <div
          *ngSwitchCase="'performance_feed'"
          mt-claim-authorization
          [requiredClaim]="'TargetGroups:View'"
        >
          <li
            (click)="
              menus = navigationSidebarService.updateDropdownState(
                marketingMenus,
                menu.name
              )
            "
          >
            <a
              [routerLink]="menu.url"
              routerLinkActive="active"
              class="show"
              [ngClass]="{
                link__inactive: (menu.name | submenuInactive: authorizationScope)
              }"
            >
              <ng-container
                [ngTemplateOutlet]="renderSideMenuData"
                [ngTemplateOutletContext]="{ menu }"
              ></ng-container>
            </a>
          </li>
        </div>

        <div
          *ngSwitchCase="'recipients'"
          mt-claim-authorization
          [requiredClaim]="'TargetGroups:View'"
        >
          <li
            [ngClass]="{
              link__inactive: (menu.name | submenuInactive: authorizationScope)
            }"
            (click)="
              menus = navigationSidebarService.updateDropdownState(
                marketingMenus,
                menu.name
              )
            "
          >
            <a
              [ngClass]="{
                link__inactive: (menu.name | submenuInactive: authorizationScope)
              }"
            >
              <ng-container
                [ngTemplateOutlet]="renderSideMenuData"
                [ngTemplateOutletContext]="{ menu }"
              ></ng-container>
            </a>
          </li>

          <ng-container *ngIf="menu.submenu && menu.isDropdownOpen"
            ><ng-container *ngFor="let subMenu of menu.submenu">
              <li
                [ngClass]="{
                  link__inactive: (menu.name | submenuInactive: authorizationScope)
                }"
                class="mt-submenu-title_subtitle-wrapper"
              >
                <a
                  [routerLink]="subMenu.url"
                  routerLinkActive="active"
                  class="show"
                >
                  <div class="mt-submenu-icon marleft20">
                    <mt-svg class="h12 mt-white" src="{{ subMenu.image }}">
                    </mt-svg>
                  </div>
                  <span
                    class="mt-submenu-title_subtitle marleft5"
                    [transloco]="subMenu.name"
                    [attr.data-cy]="menu.name + '-subMenu-' + subMenu.name"
                  ></span>
                </a>
              </li> </ng-container
          ></ng-container>
        </div>

        <div
          *ngSwitchCase="'Leads'"
          mt-claim-authorization
          [requiredClaim]="'TargetGroups:View'"
        >
          <ng-container
            *ngIf="
              navigationSidebarService.isRecipientsLeads$
                | async as recipientsLeads
            "
          >
            <li
              *ngIf="
                recipientsLeads.recordForeignContacts ||
                recipientsLeads.recordForeignDomains
              "
              [ngClass]="{
                link__inactive: (menu.name | submenuInactive: authorizationScope)
              }"
              (click)="
                menus = navigationSidebarService.updateDropdownState(
                  marketingMenus,
                  menu.name
                )
              "
            >
              <a
                [ngClass]="{
                  link__inactive: (menu.name | submenuInactive: authorizationScope)
                }"
              >
                <ng-container
                  [ngTemplateOutlet]="renderSideMenuData"
                  [ngTemplateOutletContext]="{ menu }"
                ></ng-container>
              </a>
            </li>
            <ng-container *ngIf="menu.isDropdownOpen">
              <ng-container *ngFor="let subMenu of menu.submenu; let i = index">
                <li
                  *ngIf="
                    subMenu.name === 'accounts'
                      ? recipientsLeads.recordForeignDomains
                      : recipientsLeads.recordForeignContacts
                  "
                  [ngClass]="{
                    link__inactive: (menu.name | submenuInactive: authorizationScope)
                  }"
                  class="mt-submenu-title_subtitle-wrapper"
                >
                  <a
                    [routerLink]="subMenu.url"
                    routerLinkActive="active"
                    class="show"
                  >
                    <div class="mt-submenu-icon marleft20">
                      <mt-svg class="h12 mt-white" src="{{ subMenu.image }}">
                      </mt-svg>
                    </div>
                    <span
                      class="mt-submenu-title_subtitle marleft5"
                      [transloco]="subMenu.name"
                      [attr.data-cy]="menu.name + '-subMenu-' + subMenu.name"
                    ></span>
                  </a>
                </li>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>

    <!-- Displays sidemenu data -->
    <ng-template let-menu="menu" #renderSideMenuData>
      <div class="mt-submenu-icon">
        <mt-svg
          class="tiny-svg"
          [ngClass]="
            (menu.name
            | submenuInactive
              : authorizationScope)
              ? 'mt-grau'
              : 'mt-white'
          "
          src="{{ menu.image }}"
        >
        </mt-svg>
      </div>
      <span
        class="mt-submenu-title inline-block"
        [transloco]="menu.name"
        [attr.data-cy]="'menu-' + menu.name"
      ></span>
      <span *ngIf="menu.hasCounter" class="mt-submenu-counter">
        <ng-container [ngSwitch]="menu.name">
          <ng-container *ngSwitchCase="'CAMPAIGNS'">
            {{ campaignCount }}
          </ng-container>

          <ng-container *ngSwitchCase="'events'">
            {{ eventCount }}
          </ng-container>

          <ng-container *ngSwitchCase="'notifications'">
            {{ notificationCount }}
          </ng-container>

          <ng-container *ngSwitchCase="'target_group_s'">
            {{ targetGroupCount }}
          </ng-container>
        </ng-container>
      </span>
      <mt-svg
        *ngIf="!menu.hasCounter && menu.submenu && menu.submenu.length"
        class="mt-submenu-dropdown-arrow martop4 mt-white"
        src="assets/images/sidebar/down-triangle.svg"
      >
      </mt-svg> </ng-template
  ></ng-container>
</ul>
