import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mt-navigation-sidebar-submenu-header',
  templateUrl: './navigation-sidebar-submenu-header.component.html',
  styleUrls: ['./navigation-sidebar-submenu-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationSidebarSubmenuHeaderComponent {
  /**
   * The submenu title
   * @defaultValue `employees`
   */
  @Input() submenuTitle = 'employees';

  /**
   * The check of submenuLocked
   * @defaultValue `false`
   */
  @Input() submenuLocked = false;

  /**
   * Triggers of toggleSubmenuLock
   */
  @Output() toggleSubmenuLock = new EventEmitter<void>();
}
