import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { NoMobileViewComponent } from './no-mobile-view.component';
import { MtIconsModule } from 'projects/mailtastic-icons-library/src/lib/mt-icons/mt-icons.module';

@NgModule({
  declarations: [NoMobileViewComponent],
  imports: [CommonModule, TranslocoModule, MtIconsModule],
  exports: [NoMobileViewComponent]
})
export class NoMobileViewModule {}
