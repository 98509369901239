import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { NavigationSidebarMenu } from '@modules/root/model/navigation-sidebar-menu.interface';
import { NavigationSidebarService } from '@services/navigation-sidebar/navigation-sidebar.service';
import { BehaviorSubject } from 'rxjs';

const INTEGRATIONS_MENU: NavigationSidebarMenu[] = [
  {
    image: 'assets/images/sidebar/settings.svg',
    name: 'integration_finder',
    url: '/integrations/filter'
  },
  {
    image: 'assets/images/sidebar/settings.svg',
    name: 'myintegrations',
    url: '/integrations/allintegrations'
  },
  {
    image: 'assets/images/sidebar/settings.svg',
    name: 'nav_integrations',
    isDropdownOpen: true,
    submenu: [
      { name: 'all_integrations', url: '/integrations/allintegrations' },
      { name: 'installsignature', url: '/integrations/allintegrations' },
      { name: 'importusers', url: '/integrations/allintegrations' },
      { name: 'marketing_integrations', url: '/integrations/allintegrations' }
    ]
  },
  {
    image: 'assets/images/sidebar/settings.svg',
    name: 'integration_list_office365',
    url: '/integrations/allintegrations'
  },
  {
    image: 'assets/images/sidebar/settings.svg',
    name: 'source_gsuite',
    url: '/integrations/allintegrations'
  },
  {
    image: 'assets/images/sidebar/settings.svg',
    name: 'software_and_downloads',
    url: '/integrations/softwareanddownloads'
  }
];

@Component({
  selector: 'mt-navigation-sidebar-integration-submenu',
  templateUrl: './navigation-sidebar-integration-submenu.component.html',
  styleUrls: ['./navigation-sidebar-integration-submenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationSidebarIntegrationSubmenuComponent {
  navigationSidebarService = inject(NavigationSidebarService);

  /**
   * Holds the current integration menu state
   */
  private _integrationsMenus$ = new BehaviorSubject(INTEGRATIONS_MENU);

  /**
   * The number of active integrations
   * @defaultValue 0
   */
  @Input() activeIntegrationCount = 0;

  /**
   * Emits the current integration menu state
   */
  integrationsMenus$ = this._integrationsMenus$.asObservable();

  /**
   * Sets new menu items
   * @param menuItems - New menu items to set
   */
  set menus(menuItems: NavigationSidebarMenu[]) {
    this._integrationsMenus$.next(menuItems);
  }
}
