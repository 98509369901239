import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IAccountData, SuperAdminAccountData } from 'src/app/model/interfaces/account-admin.interface';
import { IExternalAdminGetAccounts } from 'src/app/services/external-admin/external-admin.interface';
import { UtilService } from 'src/app/services/util/util.service';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs';
import { sortBy } from 'lodash-es';
import { CustomerEmails, CustomerLogin } from '@services/user/user-service.interface';

@Component({
  selector: 'mt-top-info-banner',
  templateUrl: './top-info-banner.component.html',
  styleUrls: ['./top-info-banner.component.scss']
})
export class TopInfoBannerComponent {
  /**
   * The email array of all customers
   * @defaultValue []
   */
  @Input() allCustomers: CustomerEmails[] = [];

  /**
   * The superadmin account data
   */
  @Input() superAdminAccountData!: SuperAdminAccountData;

  /**
   * The array of customer email
   * @defaultValue []
   */
  @Input() recentCustomersEmail: string[] = [];

  /**
   * Child element of connected admin account tooltip
   */
  @ViewChild('adminAccountsTooltip') adminAccountsTooltip!: { close: () => void };

  /**
   * Hold user has subscription or not
   */
  @Input() hasSubscription!: boolean;

  /**
   * Hold user in testing period
   * After signup we provide 14 days trial period
   */
  @Input() hasTestTime!: boolean;

  /**
   * Hold system access forcefully without subscription
   */
  @Input() forceAllow!: boolean;

  /**
   * Hold to know user signup from new angular
   * @defaultValue false
   */
  @Input() isUserFromAngularTs = false;

  /**
   * Hold remaining number of days for trial subscription expiry
   */
  @Input() trialDaysLeft!: number;

  /**
   * The account data including company name, firstname, lastname and email
   */
  @Input() accountData!: IAccountData;

  /**
   * The admin accounts
   */
  @Input() adminAccounts!: IExternalAdminGetAccounts[];

  /**
   * Triggers external admin id
   */
  @Output() changeAccount = new EventEmitter<string>();

  /**
   * Triggers customer login action
   */
  @Output() customerLogin = new EventEmitter<CustomerLogin>();

  /**
   * Emits the search text/value of customer email
   */
  @Output() searchValue = new EventEmitter<string>();

  // Temporary hide
  /**
   * The email notification data
   */
  // @Input() emailNotficationSettings!: INotificationSetting;

  /**
   * Change email notification settings
   */
  // @Output() changeSettings = new EventEmitter<{
  //   emailNotficationSettings: INotificationSetting;
  //   setting: string;
  // }>();

  /**
   * Trigger to logout from the application
   */
  @Output() logout = new EventEmitter<void>();

  /**
   * Text input of account filter
   */
  searchTxt = new FormControl<string>('', { nonNullable: true });

  /**
   * Search admin account
   */
  filteredAccounts$ = this.searchTxt.valueChanges.pipe(
    startWith(''),
    map(value => {
      return this.adminAccounts.filter(option => option.companyName.toLowerCase().includes(value.toLowerCase()));
    }),
    map(filteredOption => sortBy(filteredOption, ['companyName']))
  );

  constructor(public utilsService: UtilService) {}
}
