import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * @remarks This interceptor is required in the transloco-root.module.ts file for integration
 */
@Injectable()
export class TranslocoInterceptor implements HttpInterceptor {
  preSaveTranslation(translation: any, lang: string) {
    return translation; // Simply return the translation without modifications
  }

  preSaveTranslationKey(key: string, value: any) {
    return value; // Return the value without modifications
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If needed: Logic to modify the request (e.g., add language header)

    return next.handle(request);
  }
}
