import { Pipe, PipeTransform } from '@angular/core';

export interface NavbarHeaderAdjustment {
  hasSubscription: boolean;
  isGuideExist: boolean;
  isGuidePage: {
    isGuidePage: boolean;
  };
}

/**
 * Return with class name that applied in header of navigation sidebar
 */
@Pipe({
  name: 'headerAdjustment'
})
export class HeaderAdjustmentPipe implements PipeTransform {
  transform(loadInfo: NavbarHeaderAdjustment, isCognismSignatures: boolean): string {
    if (isCognismSignatures && (!loadInfo.isGuideExist || loadInfo.isGuidePage.isGuidePage)) {
      return 'martop0imp';
    } else {
      return '';
    }
  }
}
