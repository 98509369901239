import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthService } from '../services/auth/auth.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MailtasticAuthorizationDetails } from '@model/enums/mailtastic-authorization-details.enum';

const apisAllowedToNonMTAdmin = [
  '/azure/o365Connections',
  '/azure/authurl/international/true',
  '/azure/credentials',
  '/azure/getAccessToken',
  '/azure/administrator',
  '/googleSync',
  '/googleSync/listusers',
  '/employees/manySyncUsers',
  '/googleSync/updatesignature',

  '/crm/hubspot/companyinfo',
  '/crm/pardot/companyinfo',
  '/crm/marketo/companyinfo',

  '/crm/hubspot/companyInfo',
  '/crm/pardot/companyInfo',
  '/crm/marketo/companyInfo',

  '/crmauth/hubspot/getRedirectUri/true',
  '/crmauth/pardot/getRedirectUri/true',
  '/crm/marketo/test',
  '/v2/accountInfo/send-connection-success-mail/mt-admin'
];

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.includes('assets')) {
      const authToken = this.auth.getToken();
      const isNonAdminAccessData = this.auth.getLocalStorage(MailtasticAuthorizationDetails.accessData);

      const isNonAdminAllowedApi =
        apisAllowedToNonMTAdmin.includes(request.url) ||
        request.url.includes('/crm/account') ||
        request.url.includes('/crmauth/getToken?code=');

      const authorizationToken =
        authToken === '{}' && isNonAdminAccessData && isNonAdminAllowedApi
          ? `${this.auth.getTokenForNonMtAdmin()}`
          : `${this.auth.getToken()}`;

      request = request.clone({
        setHeaders: {
          Authorization: authorizationToken
        },
        url: `${environment.apiUrl}${request.url}`
      });
    }
    return next.handle(request);
  }
}
