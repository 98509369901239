<header>
  <span
    *ngIf="submenuTitle"
    class="mt-submenu-sidebar-title"
    [transloco]="submenuTitle"
  ></span>
  <span class="mt-submenu-sidebar-lock" (click)="toggleSubmenuLock.emit()">
    <mt-svg
      class="toggle-submenu-unlocked"
      *ngIf="!submenuLocked; else locked"
      src="assets/images/sidebar/unlocked.svg"
    >
    </mt-svg>
    <ng-template #locked>
      <mt-svg
        class="toggle-submenu-locked"
        src="assets/images/sidebar/locked.svg"
      ></mt-svg>
    </ng-template>
  </span>
</header>
