<!-- Submenu section -->
<ng-container *transloco="let translate"
  ><ul
    class="mt-submenu-sidebar-link-wrapper mt-white sidebar-submenu"
    *ngIf="employeeMenus$ | async as employeeMenus"
  >
    <ng-container *ngFor="let menu of employeeMenus">
      <div
        *ngIf="menu.name === 'EMPLOYEE'"
        mt-claim-authorization
        [requiredClaims]="[
          'Employee:View',
          'Employee:Manage',
          'Department:View'
        ]"
      >
        <div mt-claim-authorization [requiredClaim]="'Employee:View'">
          <li
            (click)="
              menus = navigationSidebarService.updateDropdownState(
                employeeMenus,
                menu.name
              )
            "
          >
            <a [routerLink]="menu.url" routerLinkActive="active" class="show">
              <ng-container
                [ngTemplateOutlet]="renderSideMenuData"
                [ngTemplateOutletContext]="{ menu }"
              ></ng-container>
            </a>
          </li>

          <ng-container *ngIf="menu.submenu && menu.isDropdownOpen">
            <ng-container *ngFor="let subMenu of menu.submenu">
              <div
                *ngIf="subMenu.name === 'all-employees'"
                mt-claim-authorization
                [requiredClaim]="'Employee:View'"
              >
                <li class="mt-submenu-title_subtitle-wrapper">
                  <a
                    [routerLink]="subMenu.url"
                    routerLinkActive="active"
                    class="show"
                  >
                    <div class="mt-submenu-icon marleft20">
                      <mt-svg class="h12 mt-white" src="{{ subMenu.image }}">
                      </mt-svg>
                    </div>
                    <span class="mt-submenu-title_subtitle marleft5">
                      {{ translate(subMenu.name) }}
                    </span>
                    <span *ngIf="subMenu.hasCounter" class="mt-submenu-counter">
                      {{
                        subMenu.name === 'all-employees'
                          ? allEmployeeCount
                          : outdatedEmployeeCount
                      }}
                    </span>
                  </a>
                </li>
              </div>

              <div
                *ngIf="subMenu.name !== 'all-employees'"
                mt-claim-authorization
                [requiredClaim]="'Employee:Manage'"
              >
                <li
                  class="mt-submenu-title_subtitle-wrapper"
                  *ngIf="
                    subMenu.name !== 'available-from-directory' ||
                    (subMenu.name === 'available-from-directory' &&
                      showNotYetImported)
                  "
                >
                  <a
                    [routerLink]="subMenu.url"
                    routerLinkActive="active"
                    class="show"
                  >
                    <div class="mt-submenu-icon marleft20">
                      <mt-svg class="h12 mt-white" src="{{ subMenu.image }}">
                      </mt-svg>
                    </div>
                    <span class="mt-submenu-title_subtitle marleft5">
                      {{ translate(subMenu.name) }}
                    </span>
                    <span *ngIf="subMenu.hasCounter" class="mt-submenu-counter">
                      {{
                        subMenu.name === 'all-employees'
                          ? allEmployeeCount
                          : outdatedEmployeeCount
                      }}
                    </span>
                  </a>
                </li>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <div
        *ngIf="menu.name === 'DEPARTMENTS'"
        mt-claim-authorization
        [requiredClaim]="'Department:View'"
      >
        <li
          (click)="
            menus = navigationSidebarService.updateDropdownState(
              employeeMenus,
              menu.name
            )
          "
        >
          <a [routerLink]="menu.url" routerLinkActive="active" class="show">
            <ng-container
              [ngTemplateOutlet]="renderSideMenuData"
              [ngTemplateOutletContext]="{ menu }"
            ></ng-container>
          </a>
        </li>

        <ng-container *ngIf="menu.submenu && menu.isDropdownOpen">
          <ng-container *ngFor="let subMenu of menu.submenu">
            <li
              class="mt-submenu-title_subtitle-wrapper"
              *ngIf="
                subMenu.name !== 'available-from-directory' ||
                (subMenu.name === 'available-from-directory' &&
                  showNotYetImported)
              "
            >
              <a
                [routerLink]="subMenu.url"
                routerLinkActive="active"
                class="show"
              >
                <div class="mt-submenu-icon marleft20">
                  <mt-svg class="h12 mt-white" src="{{ subMenu.image }}">
                  </mt-svg>
                </div>
                <span class="mt-submenu-title_subtitle marleft5">
                  {{ translate(subMenu.name) }}
                </span>
                <span *ngIf="subMenu.hasCounter" class="mt-submenu-counter">
                  {{
                    subMenu.name === 'all-employees'
                      ? allEmployeeCount
                      : outdatedEmployeeCount
                  }}
                </span>
              </a>
            </li>
          </ng-container>
        </ng-container>
      </div>

      <ng-container *ngIf="menu.name === 'datasets'"
        ><div>
          <li
            (click)="
              menus = navigationSidebarService.updateDropdownState(
                employeeMenus,
                menu.name
              )
            "
          >
            <a [routerLink]="menu.url" routerLinkActive="active" class="show">
              <ng-container
                [ngTemplateOutlet]="renderSideMenuData"
                [ngTemplateOutletContext]="{ menu }"
              ></ng-container>
            </a>
          </li>

          <ng-container *ngIf="menu.submenu && menu.isDropdownOpen">
            <ng-container *ngFor="let subMenu of menu.submenu">
              <li
                class="mt-submenu-title_subtitle-wrapper"
                *ngIf="
                  subMenu.name !== 'available-from-directory' ||
                  (subMenu.name === 'available-from-directory' &&
                    showNotYetImported)
                "
              >
                <a
                  [routerLink]="subMenu.url"
                  routerLinkActive="active"
                  class="show"
                >
                  <div class="mt-submenu-icon marleft20">
                    <mt-svg class="h12 mt-white" src="{{ subMenu.image }}">
                    </mt-svg>
                  </div>
                  <span class="mt-submenu-title_subtitle marleft5">
                    {{ translate(subMenu.name) }}
                  </span>
                  <span *ngIf="subMenu.hasCounter" class="mt-submenu-counter">
                    {{
                      subMenu.name === 'all-employees'
                        ? allEmployeeCount
                        : outdatedEmployeeCount
                    }}
                  </span>
                </a>
              </li>
            </ng-container>
          </ng-container>
        </div></ng-container
      >
      <!-- <li
        (click)="
          menus = navigationSidebarService.updateDropdownState(
            employeeMenus,
            menu.name
          )
        "
      >
        <a [routerLink]="menu.url" routerLinkActive="active" class="show">
          <ng-container
            [ngTemplateOutlet]="renderSideMenuData"
            [ngTemplateOutletContext]="{ menu }"
          ></ng-container>
        </a>
      </li>

      <ng-container *ngIf="menu.submenu && menu.isDropdownOpen">
        <ng-container *ngFor="let subMenu of menu.submenu">
          <li
            class="mt-submenu-title_subtitle-wrapper"
            *ngIf="
              subMenu.name !== 'available-from-directory' ||
              (subMenu.name === 'available-from-directory' &&
                showNotYetImported)
            "
          >
            <a
              [routerLink]="subMenu.url"
              routerLinkActive="active"
              class="show"
            >
              <div class="mt-submenu-icon marleft20">
                <mt-svg class="h12 mt-white" src="{{ subMenu.image }}">
                </mt-svg>
              </div>
              <span class="mt-submenu-title_subtitle marleft5">
                {{ translate(subMenu.name) }}
              </span>
              <span *ngIf="subMenu.hasCounter" class="mt-submenu-counter">
                {{
                  subMenu.name === 'all-employees'
                    ? allEmployeeCount
                    : outdatedEmployeeCount
                }}
              </span>
            </a>
          </li>
        </ng-container>
      </ng-container> -->
    </ng-container>
  </ul></ng-container
>

<ng-template let-menu="menu" let-employeeMenus="employeeMenus" #renderSideMenu>
  <ng-container *transloco="let translate">
    <li
      (click)="
        menus = navigationSidebarService.updateDropdownState(
          employeeMenus,
          menu.name
        )
      "
    >
      <a [routerLink]="menu.url" routerLinkActive="active" class="show">
        <ng-container
          [ngTemplateOutlet]="renderSideMenuData"
          [ngTemplateOutletContext]="{ menu }"
        ></ng-container>
      </a>
    </li>

    <ng-container *ngIf="menu.submenu && menu.isDropdownOpen">
      <ng-container *ngFor="let subMenu of menu.submenu">
        <li
          class="mt-submenu-title_subtitle-wrapper"
          *ngIf="
            subMenu.name !== 'available-from-directory' ||
            (subMenu.name === 'available-from-directory' && showNotYetImported)
          "
        >
          <a [routerLink]="subMenu.url" routerLinkActive="active" class="show">
            <div class="mt-submenu-icon marleft20">
              <mt-svg class="h12 mt-white" src="{{ subMenu.image }}"> </mt-svg>
            </div>
            <span class="mt-submenu-title_subtitle marleft5">
              {{ translate(subMenu.name) }}
            </span>
            <span *ngIf="subMenu.hasCounter" class="mt-submenu-counter">
              {{
                subMenu.name === 'all-employees'
                  ? allEmployeeCount
                  : outdatedEmployeeCount
              }}
            </span>
          </a>
        </li>
      </ng-container>
    </ng-container></ng-container
  >
</ng-template>

<!-- Displays sidemenu data -->
<ng-template let-menu="menu" #renderSideMenuData>
  <div class="mt-submenu-icon">
    <mt-svg class="mt-white" src="{{ menu.image }}"> </mt-svg>
  </div>
  <span class="mt-submenu-title inline-block" [transloco]="menu.name"></span>
  <span *ngIf="menu.hasCounter" class="mt-submenu-counter">
    {{ menu.name === 'EMPLOYEE' ? allEmployeeCount : groupCount }}
  </span>
  <mt-svg
    *ngIf="!menu.hasCounter && menu.submenu.length"
    class="mt-submenu-dropdown-arrow martop4 mt-white"
    src="assets/images/sidebar/down-triangle.svg"
  >
  </mt-svg>
</ng-template>
