import { HttpClient } from '@angular/common/http';
import {
  Translation,
  TranslocoLoader,
  TranslocoModule,
  TRANSLOCO_TRANSPILER,
  DefaultTranspiler,
  TRANSLOCO_MISSING_HANDLER,
  provideTransloco,
  DefaultMissingHandler,
  TRANSLOCO_INTERCEPTOR,
  TRANSLOCO_FALLBACK_STRATEGY
} from '@jsverse/transloco';
import { inject, Injectable, NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { TranslocoInterceptor } from './transloco-interceptor';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

export class DefaultFallbackStrategy {
  getFallbackLangs(failedLang: string) {
    return ['en']; // Fallback to English
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      loader: TranslocoHttpLoader,
      config: {
        availableLangs: ['en', 'de'],
        defaultLang: environment.isCogSig ? 'cognism' : 'en',
        fallbackLang: environment.isCogSig ? 'cognism' : 'en',
        reRenderOnLangChange: true,
        prodMode: environment.production,
        missingHandler: {
          useFallbackTranslation: false
        }
      }
    }),
    {
      provide: TRANSLOCO_TRANSPILER,
      useClass: DefaultTranspiler // Provide the transpiler
    },
    {
      provide: TRANSLOCO_MISSING_HANDLER,
      useClass: DefaultMissingHandler // Provide the missing handler
    },
    {
      provide: TRANSLOCO_INTERCEPTOR,
      useClass: TranslocoInterceptor // Provide the transloco interceptor
    },
    {
      provide: TRANSLOCO_FALLBACK_STRATEGY,
      useClass: DefaultFallbackStrategy // Provide the fallback strategy
    }
  ]
})
export class TranslocoRootModule {}
