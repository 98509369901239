import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, catchError, switchMap, filter, take, BehaviorSubject, of } from 'rxjs';
import { inject, Injectable } from '@angular/core';

import { AuthService } from '../services/auth/auth.service';
import { MailtasticAuthorizationDetails } from '@model/enums/mailtastic-authorization-details.enum';
import { UserService } from '@services/user/user.service';
import { environment } from 'src/environments/environment';

const apisAllowedToNonMTAdmin = [
  '/azure/o365Connections',
  '/azure/authurl/international/true',
  '/azure/credentials',
  '/azure/getAccessToken',
  '/azure/administrator',
  '/googleSync',
  '/googleSync/listusers',
  '/employees/manySyncUsers',
  '/googleSync/updatesignature',

  '/crm/hubspot/companyinfo',
  '/crm/pardot/companyinfo',
  '/crm/marketo/companyinfo',

  '/crm/hubspot/companyInfo',
  '/crm/pardot/companyInfo',
  '/crm/marketo/companyInfo',

  '/crmauth/hubspot/getRedirectUri/true',
  '/crmauth/pardot/getRedirectUri/true',
  '/crm/marketo/test',
  '/v2/accountInfo/send-connection-success-mail/mt-admin'
];

interface HttpTokenError {
  status: number;
  message?: string;
  error?: {
    message?: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
  private auth = inject(AuthService);
  private userService = inject(UserService);

  /**
   * Flag indicating if a token refresh is in progress
   */
  private isRefreshing = false;

  /**
   * Subject that emits the refreshed access token
   */
  private refreshTokenSubject = new BehaviorSubject<{ accessToken: string } | null>(null);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.includes('assets')) {
      const authToken = this.auth.getToken();
      const isNonAdminAccessData = this.auth.getLocalStorage(MailtasticAuthorizationDetails.accessData);

      const isNonAdminAllowedApi =
        apisAllowedToNonMTAdmin.includes(request.url) ||
        request.url.includes('/crm/account') ||
        request.url.includes('/crmauth/getToken?code=');

      const authorizationToken =
        authToken === '{}' && isNonAdminAccessData && isNonAdminAllowedApi
          ? `${this.auth.getTokenForNonMtAdmin()}`
          : `${this.auth.getToken()}`;

      request = request.clone({
        withCredentials: true,
        setHeaders: {
          Authorization: authorizationToken
        },
        url: `${environment.apiUrl}${request.url}`
      });
    }
    return next
      .handle(request)
      .pipe(
        catchError((error: HttpTokenError) =>
          this.expiredToken(error) ? this.refreshToken(request, next) : throwError(() => error)
        )
      );
  }

  /**
   * Checks if the error is due to an expired token
   * @param error - The HTTP error response
   * @returns true if the error status is 401 and contains a TokenExpiredError message
   */
  private expiredToken(error: HttpTokenError): boolean {
    return (
      error.status === 401 && (error.message === 'TokenExpiredError' || error?.error?.message === 'TokenExpiredError')
    );
  }

  /**
   * Refreshes the access token and retries the original request
   * @param request - The original HTTP request
   * @param next - The HTTP handler
   * @returns An Observable of the HTTP response
   */
  private refreshToken(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.auth.refreshAccessToken().pipe(
        switchMap(() => {
          this.isRefreshing = false;
          const accessToken = this.auth.getToken();
          this.refreshTokenSubject.next({ accessToken });
          return next.handle(
            request.clone({
              setHeaders: {
                Authorization: accessToken
              }
            })
          );
        }),
        catchError(() => {
          this.isRefreshing = false;
          console.error('Refresh token failed');
          return this.userService.logout().pipe(switchMap(() => of()));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(() => {
          return next.handle(
            request.clone({
              setHeaders: {
                Authorization: this.auth.getToken()
              }
            })
          );
        })
      );
    }
  }
}
