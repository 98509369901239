import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { AlertService } from '@services/alert/alert.service';
import { GetStartedGuideService } from '@services/get-started-guide/get-started-guide.service';
import { map, Observable, take } from 'rxjs';
import { environment } from 'src/environments/environment';

/**
 * Guard is used to provide access to the guide tour.
 * If admin is external admin then can not have access to guide tour.
 */
@Injectable({
  providedIn: 'root'
})
export class GuideTourGuard implements CanActivate {
  constructor(
    private alert: AlertService,
    private guideService: GetStartedGuideService,
    private translate: TranslocoService
  ) {}
  canActivate(): Observable<boolean> | boolean {
    if (environment.isCogSig) {
      return true;
    } else {
      return this.guideService.authorizationScope$.pipe(
        take(1),
        map(data => {
          if (!data.isGuideTourAccess) {
            void this.alert.defaultInfoMessage(
              this.translate.translate('alert_guide_notaccess_to_external_admin') as string
            );
          }
          return data.isGuideTourAccess;
        })
      );
    }
  }
  canActivateChild(): Observable<boolean> | boolean {
    return this.canActivate();
  }
}
